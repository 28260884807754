import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "notification-page-tools notification-page-tools--center" }
const _hoisted_2 = { class: "notification-page__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DotsSvg = _resolveComponent("DotsSvg")!
  const _component_VButtonDropdown = _resolveComponent("VButtonDropdown")!
  const _component_VSelect = _resolveComponent("VSelect")!
  const _component_VPageHeader = _resolveComponent("VPageHeader")!
  const _component_VNotificationCard = _resolveComponent("VNotificationCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_VLoader = _resolveComponent("VLoader")!
  const _component_NoDataSvg = _resolveComponent("NoDataSvg")!
  const _component_NoDataDarkSvg = _resolveComponent("NoDataDarkSvg")!
  const _component_VIllustration = _resolveComponent("VIllustration")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_openBlock(), _createBlock(_component_VPage, { class: "notification-page" }, {
    default: _withCtx(() => [
      _createVNode(_component_VPageHeader, {
        title: _ctx.pageTitle,
        "show-third-block": ""
      }, {
        second: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_VButtonDropdown, {
              class: "prevent-close",
              placement: "left-start",
              options: _ctx.options
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DotsSvg, { class: "rotate-90" })
              ]),
              _: 1
            }, 8, ["options"]),
            _createVNode(_component_VSelect, {
              class: "v-select v-select--secondary",
              label: "title",
              placeholder: _ctx.t('default-placeholder', 2),
              options: _ctx.filterOptions,
              modelValue: _ctx.filter,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event))
            }, null, 8, ["placeholder", "options", "modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_VPageContent, {
        "align-center": "",
        vertical: "",
        onScrollYNearEnd: _ctx.fetchMoreNotifications
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedNotifications, (notification, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "notification-page__item"
              }, [
                _createVNode(_component_RouterLink, {
                  to: _ctx.getGotoLink(notification)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_VNotificationCard, {
                      notification: notification,
                      onRemove: _ctx.removeNotification,
                      onClick: ($event: any) => (_ctx.markAsReadAsync(notification))
                    }, null, 8, ["notification", "onRemove", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128))
          ]),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_VLoader, {
                key: 0,
                class: "loader--mini loader--center"
              }))
            : _createCommentVNode("", true),
          (!_ctx.hasNextPage)
            ? (_openBlock(), _createBlock(_component_VIllustration, {
                key: 1,
                tip: _ctx.notifications.length > 0 ? '' : _ctx.$t('no-data')
              }, {
                default: _withCtx(() => [
                  (_ctx.theme === 'light')
                    ? (_openBlock(), _createBlock(_component_NoDataSvg, { key: 0 }))
                    : (_ctx.theme === 'dark')
                      ? (_openBlock(), _createBlock(_component_NoDataDarkSvg, { key: 1 }))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["tip"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onScrollYNearEnd"])
    ]),
    _: 1
  }))
}