
// Svg

// Components
import VFeedStory from './VFeedStory.vue';

// Other
import INotification from '@/core/Models/INotification';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    components: {
        VFeedStory,
    },

    props: {
        notification: { type: Object as PropType<INotification>, required: true },
    },
});
