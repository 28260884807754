export function groupedMap<TValue, TKey>(array: TValue[], selector: (item: TValue) => TKey): Map<TKey, TValue[]> {
    const map = new Map<TKey, TValue[]>();

    for (const item of array) {
        const key = selector(item);
        let list = map.get(key);
        if (list === undefined) {
            list = [];
            map.set(key, list);
        }
        list.push(item);
    }

    return map;
}

export function fileExtension(name: string): string {
    const dotIndex = name.lastIndexOf('.');
    return dotIndex === -1 ? name : name.substring(dotIndex, name.length);
}

export function findLast<TItem>(array: TItem[], predicate: (item: TItem) => boolean): TItem | undefined {
    for (let i = array.length - 1; i >= 0; i--) {
        if (predicate(array[i])) {
            return array[i];
        }
    }
}

export function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    document.execCommand('copy');

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string): void {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }

    navigator.clipboard.writeText(text);
}

export function isEmptyArray(arr: unknown): boolean {
    return Array.isArray(arr) && arr.length === 0;
}

export function isNullOrUndefined(value: unknown): value is undefined | null {
    return value === null || value === undefined;
}

export function required(value: unknown): boolean {
    if (isNullOrUndefined(value) || isEmptyArray(value) || value === false) {
        return false;
    }

    return !!String(value).trim().length;
}
