
// Svg
// ...

// Components
// ...

// Other
import { debounce } from 'debounce';
import { defineComponent, markRaw } from 'vue';

type DebounceFunction = ((value: string) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    inheritAttrs: false,

    props: {
        maxlength: { type: [Number, String], default: 0 },
        modelValue: { type: String, required: true, default: '' },
        debounceMode: { type: Boolean, default: false },
        debounceInterval: { type: Number, default: 500 },
        concurrencyMode: { type: Boolean, default: false },
    },

    data: () => ({
        value: '',
        focused: false,
        debounce: null as DebounceFunction | null,
    }),

    methods: {
        onInput(event: Event) {
            const value = (event.target as HTMLInputElement).value;
            // Fallback if the `maxlength` attribute does not work.
            if (this.maxlengthNumber && value.length > this.maxlengthNumber) {
                (event.target as HTMLInputElement).value = value.substring(0, this.maxlengthNumber);
                return;
            }

            this.value = value;

            const emit = (newValue: string) => {
                this.$emit('update:modelValue', newValue);
                this.debounce = null;
            };

            if (this.debounceMode && !this.debounce) {
                this.debounce = markRaw(debounce(emit, this.debounceInterval));
            }

            if (this.debounceMode && this.debounce) {
                this.debounce(value);
            } else {
                emit(value);
            }
        },
    },

    computed: {
        maxlengthNumber(): number {
            return parseInt(this.maxlength as string, 10) || 0;
        },
    },

    watch: {
        modelValue(value: string) {
            // If the user is in the input window, ignore all changes.
            if (this.concurrencyMode && this.focused) {
                return;
            }

            this.value = value;
        },
    },

    created() {
        this.value = this.modelValue;
    },
});
