// Svg
import OpenSvg from '@/assets/open.svg';
import EditSvg from '@/assets/edit.svg';
import CheckSvg from '@/assets/check.svg';
import CompleteSvg from '@/assets/complete.svg';
import TimesSvg from '@/assets/times.svg';
import RollbackSvg from '@/assets/rollback.svg';
import ShareSvg from '@/assets/share.svg';
import ArchiveSvg from '@/assets/archive.svg';
import NotesCheckedSvg from '@/assets/notes-checked.svg';

// Other
import Operations from '@/core/Authorization/Operations';
import ITask from '@/core/Models/ITask';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import Status from '@/core/Values/Status';
import { h, markRaw, ref, computed, Ref } from 'vue';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import { $confirm } from '@/utils/app-utils';
import TaskType from '@/core/Values/TaskType';
import IChatComment from '@/core/Values/IChatComment';
import IStory from '@/core/Models/IStory';
import StoryType from '@/core/Values/StoryType';
import { copyTextToClipboard, findLast } from '@/utils/utils';
import store from '@/store';
import IAttachment from '@/core/Values/IAttachment';
import UploadableFile from '@/core/Uploader/UploadableFile';
import IApiResult from '@/core/IApiResult';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import ConfirmContext from '@/core/Values/ConfirmContext';
import ApprovementStatus from '@/core/Values/ApprovementStatus';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import Storages from '@/core/Storages';
import StoryViewMode from '@/core/Values/StoryViewMode';
import Settings from '@/core/Settings';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import { MenuItem } from '@imengyu/vue3-context-menu';
import { useI18n } from 'vue-i18n';
import APITaskMixin from './APITaskMixin';
import ContextMenu from '@imengyu/vue3-context-menu';

interface ITaskAction extends IDropdownOption {
    show: boolean;
    panelClasses?: string;
    isMainAction: boolean;
}

function validateDefault(callback?: () => void) {
    if (callback) {
        callback();
    }
}

function showApproverSelectorDefault() {
    return;
}

export default function setup(
    taskOrGoal: Ref<ITask>,
    validate = validateDefault,
    showApproverSelector = showApproverSelectorDefault,
) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const {
        approveAsync,
        disapproveAsync,
        completeAsync,
        finishAsync,
        toInProgressAsync,
        rollbackChangesAsync,
        archiveAsync,
        toDraftAsync,
        updateCommentAsync,
        createCommentAsync,
        createAttachmentAsync,
        removeStoryAsync,
    } = APITaskMixin;

    const newComment = ref({
        id: -1,
        text: '',
        attachments: [],
    } as IChatComment);
    const editComment = ref(null as IChatComment | null);

    const storyViewMode = computed(Storages.Settings.computed(Settings.UI.StoryViewMode, StoryViewMode.All));

    const taskOrGoalString = computed((): string => {
        return taskOrGoal.value.type === TaskType.Goal ? 'goal' : 'task';
    });

    const editable = computed((): boolean => {
        return taskOrGoal.value.status === Status.Draft;
    });

    const disabled = computed((): boolean => {
        return (
            !!taskOrGoal.value.archivedAt ||
            taskOrGoal.value.status === Status.Completed ||
            taskOrGoal.value.status === Status.Rejected ||
            taskOrGoal.value.status === Status.Finished
        );
    });

    const canUpdate = computed((): boolean => {
        return AuthorizationProvider.authorize(taskOrGoal.value, Operations.UpdateTask);
    });

    const canUpdateAuthor = computed((): boolean => {
        return AuthorizationProvider.authorize(taskOrGoal.value, Operations.UpdateTaskAuthor);
    });

    const canComment = computed((): boolean => {
        return AuthorizationProvider.authorize(taskOrGoal.value, Operations.Comment);
    });

    const canManageCollaborators = computed((): boolean => {
        return (
            AuthorizationProvider.authorize(taskOrGoal.value, Operations.AddCollaborator) &&
            AuthorizationProvider.authorize(taskOrGoal.value, Operations.RemoveCollaborator)
        );
    });

    const actions = computed((): ITaskAction[] => {
        const approvement = taskOrGoal.value.approvements?.find(
            (approvement) =>
                approvement.status === ApprovementStatus.Waiting &&
                approvement.approverId === store.state.user?.id &&
                AuthorizationProvider.authorize(approvement, Operations.ApproveTask),
        );

        const actions: ITaskAction[][] = [
            [
                {
                    show: taskOrGoal.value.status === Status.Approvement && !!approvement,
                    icon: markRaw(CheckSvg),
                    title: t('approve'),
                    action: () =>
                        $confirm({
                            title: t('confirm-task-or-goal-approve'),
                            message: '',
                            withComment: true,
                            commentHint: t('confirm-task-or-goal-approve-hint'),
                            commentRequired: false,
                            commentMaxLength: 512,
                            buttonOkName: t('approve'),
                            buttonOkClasses: 'button--green',
                            okCallback: (comment) => {
                                if (!approvement) {
                                    return;
                                }

                                approvement.status = ApprovementStatus.Approved;
                                approveAsync(taskOrGoal.value, { approvement, comment });
                            },
                        }),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: true,
                },
                {
                    show: taskOrGoal.value.status === Status.Approvement && !!approvement,
                    icon: markRaw(TimesSvg),
                    title: t('disapprove'),
                    action: () =>
                        $confirm({
                            title: t('confirm-task-or-goal-disapprove'),
                            message: '',
                            withComment: true,
                            commentRequired: true,
                            commentMaxLength: 512,
                            buttonOkName: t('disapprove'),
                            okCallback: (comment) => {
                                if (!approvement) {
                                    return;
                                }

                                approvement.status = ApprovementStatus.Disapproved;
                                disapproveAsync(taskOrGoal.value, { approvement, comment });
                            },
                        }),
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: true,
                },
            ],
            [
                {
                    show:
                        !disabled.value &&
                        taskOrGoal.value.status === Status.Draft &&
                        AuthorizationProvider.authorize(taskOrGoal.value, Operations.StartTaskApprovementProcess),
                    icon: markRaw(NotesCheckedSvg),
                    title: t('send-for-approvement'),
                    action: () => validate(showApproverSelector),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: true,
                },
                {
                    show:
                        !disabled.value &&
                        taskOrGoal.value.status === Status.Approvement &&
                        AuthorizationProvider.authorize(taskOrGoal.value, Operations.StartTaskApprovementProcess),
                    icon: markRaw(NotesCheckedSvg),
                    title: t('resend-for-approvement'),
                    action: showApproverSelector,
                    panelClasses: 'button--secondary button--green',
                    isMainAction: !approvement,
                },
                {
                    show:
                        !disabled.value &&
                        taskOrGoal.value.status === Status.InProgress &&
                        AuthorizationProvider.authorize(taskOrGoal.value, Operations.CompleteTask),
                    icon: markRaw(CompleteSvg),
                    title: t('complete'),
                    action: () =>
                        confirmSimpleAction(
                            t(`confirm-${taskOrGoalString.value}-complete`),
                            () => completeAsync(taskOrGoal.value),
                            '',
                            {
                                buttonOkClasses: 'button--green',
                                commentMaxLength: 512,
                            },
                        ),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: true,
                },
                {
                    show:
                        taskOrGoal.value.status === Status.Completed &&
                        AuthorizationProvider.authorize(taskOrGoal.value, Operations.FinishTask),
                    icon: markRaw(CheckSvg),
                    title: t('finish'),
                    action: () =>
                        confirmSimpleAction(
                            t(`confirm-${taskOrGoalString.value}-finish`),
                            () => finishAsync(taskOrGoal.value),
                            '',
                            {
                                buttonOkClasses: 'button--green',
                            },
                        ),
                    panelClasses: 'button--secondary button--green',
                    isMainAction: true,
                },
                {
                    show:
                        taskOrGoal.value.status === Status.Completed &&
                        AuthorizationProvider.authorize(taskOrGoal.value, Operations.ReturnTaskToInProgress),
                    icon: markRaw(TimesSvg),
                    title: t('return-to-in-progress'),
                    action: () =>
                        $confirm({
                            title: t(`confirm-${taskOrGoalString.value}-to-in-progress`),
                            message: '',
                            withComment: true,
                            commentHint: t(`confirm-task-or-goal-to-in-progress-hint`),
                            commentRequired: false,
                            commentMaxLength: 512,
                            buttonOkName: t('yes'),
                            okCallback: (comment) => toInProgressAsync(taskOrGoal.value, comment),
                        }),
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: true,
                },
                {
                    show: true,
                    icon: markRaw(ShareSvg),
                    title: t('copy-link'),
                    action: () => {
                        const uri =
                            window.location.origin + router.resolve(getTaskRouteLocation(taskOrGoal.value)).fullPath;
                        copyTextToClipboard(uri);
                    },
                    panelClasses: 'button--secondary button--green',
                    isMainAction: false,
                },
            ],
            [
                {
                    show:
                        !editable.value &&
                        !disabled.value &&
                        AuthorizationProvider.authorize(taskOrGoal.value, Operations.UpdateTask),
                    icon: markRaw(EditSvg),
                    title: t('edit'),
                    action: () => confirmToDraft(),
                    classes: 'button--transparent-negative',
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: false,
                },
                {
                    show:
                        editable.value &&
                        !!taskOrGoal.value.savePointsCount &&
                        AuthorizationProvider.authorize(taskOrGoal.value, Operations.RollbackChanges),
                    icon: markRaw(RollbackSvg),
                    title: t('rollback-changes'),
                    action: () =>
                        validate(() =>
                            confirmSimpleAction(t(`confirm-rollback-changes`), () =>
                                rollbackChangesAsync(taskOrGoal.value),
                            ),
                        ),
                    classes: 'button--transparent-negative',
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: false,
                },
                {
                    show:
                        !taskOrGoal.value.archivedAt &&
                        AuthorizationProvider.authorize(taskOrGoal.value, Operations.ArchiveTask),
                    icon: markRaw(ArchiveSvg),
                    title: t('archive'),
                    action: () =>
                        confirmSimpleAction(
                            t(`confirm-${taskOrGoalString.value}-archiving`),
                            () => archiveAsync(taskOrGoal.value),
                            t(`confirm-${taskOrGoalString.value}-archiving-hint`),
                        ),
                    classes: 'button--transparent-negative',
                    panelClasses: 'button--secondary button--negative',
                    isMainAction: false,
                },
            ],
        ];

        return actions.reduce((carry, actions) => {
            actions = actions.filter((action) => action.show);

            if (!carry.length) {
                return actions;
            }

            if (actions.length) {
                carry = [
                    ...carry,
                    {
                        show: true,
                        title: '',
                        action: () => 0,
                        classes: 'button__dropdown-option--separator',
                        isMainAction: false,
                    },
                    ...actions,
                ];
            }

            return carry;
        }, [] as ITaskAction[]);
    });

    function getTaskRouteLocation(task: ITask): RouteLocationRaw {
        if (task.type === TaskType.Goal) {
            return {
                name: 'goals.view',
                params: {
                    goalId: task.id,
                },
            };
        }

        return {
            query: {
                task: task.id,
            },
        };
    }

    function stopEdit(): void {
        editComment.value = null;
    }

    function startEdit(story?: IStory): void {
        if (story && story.type === StoryType.Comment && taskOrGoal.value.stories?.indexOf(story) !== -1) {
            editComment.value = {
                id: story.id,
                text: story.text,
                attachments: [],
            };
        }

        if (editComment.value || !taskOrGoal.value.stories || taskOrGoal.value.stories.length === 0) {
            return;
        }

        const lastStory = findLast(
            taskOrGoal.value.stories,
            (story) => story.type === StoryType.Comment && story.actorId === store.state.user?.id,
        );

        if (!lastStory) {
            return;
        }

        editComment.value = {
            id: lastStory.id,
            text: lastStory.text,
            attachments: [],
        };
    }

    function confirmToDraft(event?: { callback?: () => void }) {
        $confirm({
            message: t('confirm-task-edit', [t(taskOrGoalString.value, 2)]),
            buttonOkName: t('edit'),
            okCallback: () => toDraftAsync(taskOrGoal.value, event),
        });
    }

    function confirmSimpleAction(
        title: string,
        action: () => void,
        message = '',
        options: Partial<ConfirmContext> | null = null,
    ): void {
        $confirm({
            title,
            message,
            buttonOkName: t('yes'),
            okCallback: action,
            ...options,
        });
    }

    function updateComment(comment: IChatComment): void {
        if (comment.text) {
            updateCommentAsync(taskOrGoal.value, comment.id, comment.text);
        }
        editComment.value = null;
    }

    function createComment(comment: IChatComment): void {
        if (comment.text || comment.attachments?.length > 0) {
            createCommentAsync(taskOrGoal.value, comment);
        }
    }

    function uploadedAttachmentHandler(uploadable: UploadableFile<IApiResult<IObjectStoreModel>>): void {
        if (!uploadable.model) {
            return;
        }

        createAttachmentAsync(taskOrGoal.value, {
            fileName: uploadable.name,
            objectName: uploadable.model.data.objectName,
            extension: uploadable.extension,
            downloadUri: uploadable.model.data.downloadUri,
        } as IAttachment);
    }

    function removedAttachmentHandler(attachment: IAttachment): void {
        removeStoryAsync(taskOrGoal.value, {
            id: attachment.id,
        } as IStory);
    }

    function validateTaskFields(task: ITask): Record<string, string> | null {
        if (task.type === TaskType.Goal) {
            return {};
        }

        let formErrors: Record<string, string> | null = null;

        if (!task.title) {
            formErrors ??= {};
            formErrors.title = t('field-required');
        }

        if (!task.deadline) {
            formErrors ??= {};
            formErrors.deadline = t('field-required');
        }

        if (!task.description) {
            formErrors ??= {};
            formErrors.description = t('field-required');
        }

        if (!task.collaborators?.some((collaborator) => collaborator.role === CollaboratorRole.Assignee)) {
            formErrors ??= {};
            formErrors.collaborators = t('field-required');
        }

        return formErrors;
    }

    function validateGoalFields(goal: ITask): Record<string, string> | null {
        if (goal.type !== TaskType.Goal) {
            return {};
        }

        let formErrors: Record<string, string> | null = null;

        if (!goal.title) {
            formErrors ??= {};
            formErrors.title = t('field-required');
        }

        if (!goal.description) {
            formErrors ??= {};
            formErrors.description = t('field-required');
        }

        return formErrors;
    }

    function openContextMenu(event: MouseEvent): void {
        //prevent the browser's default menu
        event.preventDefault();

        const defaultAction: MenuItem[] = [
            {
                icon: h(OpenSvg),
                label: t('open'),
                onClick: () => {
                    const route = getTaskRouteLocation(taskOrGoal.value);
                    router.push(route);
                },
            },
            {
                icon: h(OpenSvg),
                label: t('open-in-new-tab'),
                onClick: () => {
                    const uri =
                        window.location.origin + router.resolve(getTaskRouteLocation(taskOrGoal.value)).fullPath;

                    window.open(uri, '_blank')?.focus();
                },
            },
            {
                divided: 'self',
            },
        ];

        ContextMenu.showContextMenu({
            x: event.x,
            y: event.y,
            zIndex: 100000,
            customClass: 'prevent-close',

            items: defaultAction.concat(
                actions.value.map((option): MenuItem => {
                    if (option.classes && option.classes.indexOf('button__dropdown-option--separator') !== -1) {
                        return {
                            divided: 'self',
                        };
                    }

                    return {
                        icon: option.icon ? h(option.icon) : undefined,
                        label: option.title,
                        customClass: option.classes,
                        onClick: (event) => option.action(option, event),
                    };
                }),
            ),
        });
    }

    return {
        t,
        route,
        router,

        newComment,
        editComment,

        storyViewMode,
        taskOrGoal,
        taskOrGoalString,
        editable,
        disabled,
        canUpdate,
        canUpdateAuthor,
        canComment,
        canManageCollaborators,
        actions,

        getTaskRouteLocation,
        stopEdit,
        startEdit,
        validate,
        showApproverSelector,
        confirmToDraft,
        confirmSimpleAction,
        updateComment,
        createComment,
        uploadedAttachmentHandler,
        removedAttachmentHandler,
        validateTaskFields,
        validateGoalFields,
        openContextMenu,

        ...APITaskMixin,
    };
}
