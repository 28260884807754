
// Svg
import DotsSvg from '@/assets/dots.svg';
import TextSvg from '@/assets/text.svg';
import EmailSvg from '@/assets/email.svg';
import AttachmentSvg from '@/assets/attachment.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VUserCard from './VUserCard.vue';
import VButtonDropdown from './VButtonDropdown.vue';

// Other
import ITask from '@/core/Models/ITask';
import StoryType from '@/core/Values/StoryType';
import { formatDateOnly } from '@/utils/date-utils';
import { DateTime } from 'luxon';
import Status from '@/core/Values/Status';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import ICollaborator from '@/core/Models/ICollaborator';
import IUser from '@/core/Models/IUser';
import store from '@/store';
import { defineComponent, ref } from 'vue';
import { PropType } from 'vue';
import UITaskMixin from '@/mixins/UITaskMixin';

export default defineComponent({
    components: {
        DotsSvg,
        TextSvg,
        EmailSvg,
        AttachmentSvg,

        VTaskStatus,
        VUserCard,
        VButtonDropdown,
    },

    setup(props) {
        const mixin = UITaskMixin(
            ref(props.task),
            (callback?: () => void): void => {
                let error = mixin.validateTaskFields(mixin.taskOrGoal.value);

                if (error) {
                    mixin.router.push({
                        query: {
                            task: mixin.taskOrGoal.value.id,
                            prevTask: mixin.taskOrGoal.value.parentId,
                            prevSelected: mixin.route.query.selected,
                            action: 'validate',
                        },
                    });
                    return;
                }

                callback?.();
            },
            (): void => {
                mixin.router.push({
                    query: {
                        task: mixin.taskOrGoal.value.id,
                        prevTask: mixin.taskOrGoal.value.parentId,
                        prevSelected: mixin.route.query.selected,
                        action: 'select-approvers',
                    },
                });
            },
        );

        return mixin;
    },

    props: {
        task: { type: Object as PropType<ITask>, required: true },
    },

    computed: {
        taskOrGoal(): ITask {
            return this.task;
        },

        numberOfComments(): number {
            if (typeof this.task.commentsCount === 'number') {
                return this.task.commentsCount;
            }

            return this.task.stories?.filter((story) => story.type === StoryType.Comment)?.length ?? 0;
        },

        numberOfAttachments(): number {
            if (typeof this.task.attachmentsCount === 'number') {
                return this.task.attachmentsCount;
            }

            return this.task.stories?.filter((story) => story.type === StoryType.Attachment)?.length ?? 0;
        },

        hasDescription(): boolean {
            return !!this.task.description;
        },

        formattedDeadline(): string {
            if (!this.task?.deadline) {
                return '';
            }

            return formatDateOnly(this.task.deadline);
        },

        isExpired(): boolean {
            if (!this.task.deadline || this.task.status !== Status.InProgress) {
                return false;
            }

            return DateTime.fromISO(this.task.deadline, { zone: 'UTC' }) < DateTime.now();
        },

        isFinished(): boolean {
            return this.task.status === Status.Finished;
        },

        isCompleted(): boolean {
            return this.task.status === Status.Completed;
        },

        isSelected(): boolean {
            return this.task.id === parseInt(this.$route.query.task as string, 10);
        },

        contributors(): ICollaborator[] {
            return (
                this.task.collaborators?.filter((collaborator) => collaborator.role === CollaboratorRole.Assignee) ?? []
            );
        },

        currentUser(): IUser | null {
            return store.state.user;
        },
    },
});
