import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoDataSvg = _resolveComponent("NoDataSvg")!
  const _component_NoDataDarkSvg = _resolveComponent("NoDataDarkSvg")!
  const _component_VIllustration = _resolveComponent("VIllustration")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_openBlock(), _createBlock(_component_VPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_VPageContent, {
        "align-center": "",
        "justify-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_VIllustration, {
            tip: _ctx.$t('page-not-found')
          }, {
            default: _withCtx(() => [
              (_ctx.theme === 'light')
                ? (_openBlock(), _createBlock(_component_NoDataSvg, { key: 0 }))
                : (_ctx.theme === 'dark')
                  ? (_openBlock(), _createBlock(_component_NoDataDarkSvg, { key: 1 }))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["tip"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}