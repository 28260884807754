
// Svg
import NoDataSvg from '@/assets/no-data.svg';
import NoDataDarkSvg from '@/assets/no-data-dark.svg';

// Components
import VPage from '../components/VPage.vue';
import VPageContent from '../components/VPageContent.vue';
import VIllustration from '../components/VIllustration.vue';

// Other
import { defineComponent } from 'vue';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';

export default defineComponent({
    components: {
        NoDataSvg,
        NoDataDarkSvg,

        VPage,
        VPageContent,
        VIllustration,
    },

    computed: {
        theme() {
            return Storages.Settings.get(Settings.UI.Theme);
        },
    },
});
