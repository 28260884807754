import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-page-header__column app-page-header__column--tools" }
const _hoisted_2 = { class: "column__list" }
const _hoisted_3 = { class: "column__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VTaskFilter = _resolveComponent("VTaskFilter")!
  const _component_VSearchField = _resolveComponent("VSearchField")!
  const _component_VPageHeader = _resolveComponent("VPageHeader")!
  const _component_VTaskCard = _resolveComponent("VTaskCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_VColumn = _resolveComponent("VColumn")!
  const _component_VGoalCard = _resolveComponent("VGoalCard")!
  const _component_VBoard = _resolveComponent("VBoard")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_openBlock(), _createBlock(_component_VPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_VPageHeader, {
        title: _ctx.$t('approvements'),
        "show-third-block": ""
      }, {
        second: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_VTaskFilter, {
              class: "prevent-close",
              "filter-types": [
                            _ctx.TaskFilterTypes.Author,
                            _ctx.TaskFilterTypes.Assignee,
                            _ctx.TaskFilterTypes.Contributor,
                            _ctx.TaskFilterTypes.AuthorOrAssignee,
                            _ctx.TaskFilterTypes.Deadline,
                            _ctx.TaskFilterTypes.CreationDate,
                        ],
              onChanged: _ctx.fetchData,
              modelValue: _ctx.filters,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event))
            }, null, 8, ["filter-types", "onChanged", "modelValue"]),
            _createVNode(_component_VSearchField, {
              class: "prevent-close",
              "debounce-mode": "",
              modelValue: _ctx.searchString,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchString) = $event)),
                _ctx.fetchData
              ]
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_VPageContent, null, {
        default: _withCtx(() => [
          _createVNode(_component_VBoard, null, {
            default: _withCtx(() => [
              _createVNode(_component_VColumn, {
                "wheel-propagation": "",
                column: {
                        id: -1,
                        order: -1,
                        boardId: -1,
                        projectId: -1,
                        title: _ctx.$t('tasks'),
                    }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: task.id,
                        class: "column__item"
                      }, [
                        _createVNode(_component_RouterLink, {
                          to: { query: { task: task.id } }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_VTaskCard, { task: task }, null, 8, ["task"])
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["column"]),
              _createVNode(_component_VColumn, {
                "wheel-propagation": "",
                class: "column--goals",
                column: {
                        id: -1,
                        order: -1,
                        boardId: -1,
                        projectId: -1,
                        title: _ctx.$t('goals'),
                    }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goals, (goal, goalIndex) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: goalIndex,
                        class: "column__item"
                      }, [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'goals.view', params: { goalId: goal.id } }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_VGoalCard, { goal: goal }, null, 8, ["goal"])
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["column"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}