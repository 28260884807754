import emitter from '../Emitter';
import { EventNames } from '../EventNames';
import IMutation from './IMutation';
import IMutatorContext from './IMutatorContext';

class MutationBus {
    private _contexts: IMutatorContext[] = [];

    public activate(contexts: IMutatorContext | IMutatorContext[]): void {
        if (this._contexts.length > 10) {
            console.warn('Memory leakage is possible, the number of mutation contexts exceeds the allowed maximum');
        }

        if (!Array.isArray(contexts)) {
            this._contexts.push(contexts);
            return;
        }

        for (const context of contexts) {
            this._contexts.push(context);
        }
    }

    public deactivate(contexts?: IMutatorContext | IMutatorContext[] | null): void {
        if (!contexts) {
            return;
        }

        if (!Array.isArray(contexts)) {
            contexts = [contexts];
        }

        for (const context of contexts) {
            const index = this._contexts.indexOf(context);

            if (index !== -1) {
                this._contexts.splice(index, 1);
            }
        }
    }

    public mutate(mutations: IMutation[], eventName: string): void {
        for (const context of this._contexts) {
            context.mutator.mutate(context, mutations, eventName);
        }
    }
}

const bus = new MutationBus();

const listenEventNames = [
    EventNames.DbObjectsMutated,
    EventNames.NotificationRead,
    EventNames.NotificationCreated,
    EventNames.NotificationDeleted,
];

listenEventNames.forEach((eventName) => {
    emitter.on(eventName, (mutations: IMutation[]) => bus.mutate(mutations, eventName));
});

export default bus;
