
// Components
import VGoalCard from '../components/VGoalCard.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VBoard from '../components/VBoard.vue';
import VColumn from '../components/VColumn.vue';
import VButton from '../components/VButton.vue';
import VDraggable from 'vuedraggable';

// Other
import TaskType from '@/core/Values/TaskType';
import ProjectBoardPageMixin from './_ProjectBoardPageMixin';
import { defineComponent } from 'vue';
import { markRaw } from 'vue';

export default defineComponent({
    components: {
        VGoalCard,
        VBoard,
        VColumn,
        VButton,
        VDraggable,
        VPage,
        VPageHeader,
        VPageContent,
        VTaskFilter,
        VSearchField,
    },
    mixins: [ProjectBoardPageMixin],

    data() {
        return {
            loadOnly: [TaskType.Goal],

            TaskFilterTypes: markRaw(TaskFilterTypes),
        };
    },

    created() {
        this.searchString = '';
    },
});
