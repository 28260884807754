import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import oidc from '@/vue-oidc-client';
import store from '@/store';
import { ErrorCodes } from './ErrorCodes';
import { MutationNames } from '@/store/mutations';

// prettier-ignore
const webSocketUrl = process.env.VUE_APP_WEB_SOCKET_URI
    ? process.env.VUE_APP_WEB_SOCKET_URI
    : window.location.origin + '/wss';

const connection: HubConnection = new HubConnectionBuilder()
    .withUrl(webSocketUrl, {
        accessTokenFactory: () => oidc.accessToken,
        withCredentials: false,
    })
    .configureLogging(LogLevel.Debug)
    .build();

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export class ConnectionLoop {
    private _maxTries;
    private _reconnectTime;

    public constructor(maxTries = 3, reconnectTimeInMilliseconds = 5000) {
        this._maxTries = maxTries;
        this._reconnectTime = reconnectTimeInMilliseconds;
    }

    async startAsync(connection: HubConnection) {
        async function tryConnect(maxTries = 3, reconnectTime = 5000): Promise<void> {
            let tries = 0;
            while (tries < maxTries) {
                if (connection.state === HubConnectionState.Connected) {
                    return;
                }

                try {
                    await connection.start();
                    return;
                } catch (error) {
                    await sleep(reconnectTime);
                    reconnectTime = reconnectTime * 2;
                }

                tries = tries + 1;
            }

            throw new Error(ErrorCodes.WebsocketConnectionError);
        }

        connection.onclose(async (error) => {
            if (error) {
                console.error(error);
            }

            try {
                await tryConnect();
            } catch (error) {
                store.commit(MutationNames.LoadApplicationError, error);
            }
        });

        await tryConnect(this._maxTries, this._reconnectTime);
    }
}

export default connection;
