import IMutation from '../Mutations/IMutation';
import MutationType from '../Mutations/MutationType';
import ObjectType from '../Mutations/ObjectType';
import ITask from '../Models/ITask';
import IStory from '../Models/IStory';
import UserMapper from '../UserMapper';
import IApprovement from '../Models/IApprovement';
import ICollaborator from '../Models/ICollaborator';
import { IMutator } from '../Mutations/IMutator';
import IMutatorContext from '../Mutations/IMutatorContext';
import ObjectStorageMapper from '../ObjectStorageMapper';
import IMutationPatch from '../Mutations/IMutationPatch';
import diffPatcher from '../Mutations/Patcher';
import { EventNames } from '../EventNames';
import StoryType from '../Values/StoryType';
import ITaskSavePoint from '../Models/ITaskSavePoint';

export class TaskMutator implements IMutator {
    private _matrix = {
        [ObjectType.Task]: {
            [MutationType.Created]: this.createTask,
            [MutationType.Updated]: this.updateTask,
            [MutationType.Deleted]: this.deleteTask,
            [MutationType.Patched]: this.patchTask,
        },
        [ObjectType.Story]: {
            [MutationType.Created]: this.createStory,
            [MutationType.Updated]: this.updateStory,
            [MutationType.Deleted]: this.deleteStory,
            [MutationType.Patched]: this.patchStory,
        },
        [ObjectType.Approvement]: {
            [MutationType.Created]: this.createApprovement,
            [MutationType.Updated]: this.updateApprovement,
            [MutationType.Deleted]: this.deleteApprovement,
            [MutationType.Patched]: this.patchApprovement,
        },
        [ObjectType.Collaborator]: {
            [MutationType.Created]: this.createCollaborator,
            [MutationType.Updated]: this.updateCollaborator,
            [MutationType.Deleted]: this.deleteCollaborator,
            [MutationType.Patched]: this.patchCollaborator,
        },
        [ObjectType.TaskSavePoint]: {
            [MutationType.Created]: this.createTaskSavePoint,
            [MutationType.Updated]: this.updateTaskSavePoint,
            [MutationType.Deleted]: this.deleteTaskSavePoint,
            [MutationType.Patched]: this.patchTaskSavePoint,
        },
    };

    async mutate(context: IMutatorContext, mutations: IMutation[], eventName: string): Promise<void> {
        if (eventName !== EventNames.DbObjectsMutated) {
            return;
        }

        const typedContext = context as TaskMutatorContext;
        const changedTasks = new Map<number, ITask>();
        for (const mutation of mutations) {
            const objectType = mutation.objectType as keyof typeof this._matrix;

            if (
                !Object.prototype.hasOwnProperty.call(this._matrix, mutation.objectType) ||
                !Object.prototype.hasOwnProperty.call(this._matrix[objectType], mutation.type)
            ) {
                return;
            }

            const action = this._matrix[objectType][mutation.type] as (
                context: TaskMutatorContext,
                objectState: unknown,
            ) => Promise<ITask | undefined>;

            const changedTask = await action.bind(this)(typedContext, mutation.objectState);

            if (changedTask) {
                changedTasks.set(changedTask.id, changedTask);
            }
        }

        if (!typedContext.options.excludeTask) {
            return;
        }

        for (const changedTask of changedTasks) {
            if (typedContext.options.excludeTask(changedTask[1])) {
                const index = typedContext.tasks.indexOf(changedTask[1]);
                typedContext.tasks.splice(index, 1);
            }
        }
    }

    protected async createTask(context: TaskMutatorContext, task: ITask): Promise<ITask | undefined> {
        const ignore = context.options.ignoreTaskCreating ? context.options.ignoreTaskCreating(task) : false;

        if (ignore) {
            return;
        }

        return this.updateTask(context, task);
    }

    protected async updateTask(context: TaskMutatorContext, task: ITask): Promise<ITask | undefined> {
        const carry = context.tasks.find((item) => item.id === task.id);

        if (context.options.mapParents && task.parentId) {
            task.parent = context.tasks.find((item) => item.id === task.parentId);
        }

        if (carry) {
            Object.assign(carry, task);
            if (context.options.mapUsers) {
                await UserMapper.mapTaskAsync(carry);
            }
            return carry;
        }

        if (context.options.mapUsers) {
            await UserMapper.mapTaskAsync(task);
        }

        context.tasks.push(task);
        return task;
    }

    protected async patchTask(context: TaskMutatorContext, patch: IMutationPatch): Promise<ITask | undefined> {
        if (!patch.id) {
            return;
        }

        let carry = context.tasks.find((item) => item.id === patch.id);
        // Fetch the task if it requires.
        if (!carry && context.options.fetchTask) {
            carry = await context.options.fetchTask(diffPatcher.patch({ id: patch.id }, patch.patch));

            if (carry) {
                context.tasks.push(carry);
            }
        }

        if (!carry || !patch) {
            return;
        }

        diffPatcher.patch(carry, patch.patch);

        if (context.options.mapUsers) {
            await UserMapper.mapTaskAsync(carry);
        }

        if (context.options.mapParents && Object.prototype.hasOwnProperty.call(patch.patch, 'taskId')) {
            const parent = context.tasks.find((item) => item.id === carry?.parentId);

            if (parent) {
                carry.parent = parent;
            }
        }

        return carry;
    }

    protected deleteTask(context: TaskMutatorContext, task: ITask): Promise<ITask | undefined> {
        const index = context.tasks.findIndex((item) => item.id === task.id);

        if (index !== -1) {
            context.tasks.splice(index, 1);
        }

        return Promise.resolve(undefined);
    }

    protected async createStory(context: TaskMutatorContext, story: IStory) {
        let task = context.tasks.find((item) => item.id === story.taskId);

        // Fetch the task if it requires.
        if (!task && context.options.fetchTask) {
            task = await context.options.fetchTask({ id: story.taskId, stories: [story] });

            if (task) {
                context.tasks.push(task);
            }
        }

        if (!task) {
            return;
        }

        if (story.type === StoryType.Comment && typeof task.commentsCount === 'number') {
            task.commentsCount = task.commentsCount + 1;
        }

        if (story.type === StoryType.Attachment && typeof task.attachmentsCount === 'number') {
            task.attachmentsCount = task.attachmentsCount + 1;
        }

        if (!task.stories) {
            return;
        }

        if (context.options.mapUsers) {
            await UserMapper.mapStoryAsync(story);
        }

        if (context.options.mapAttachments) {
            await ObjectStorageMapper.mapStoryAsync(story);
        }

        task.stories.push(story);

        return task;
    }

    protected async updateStory(context: TaskMutatorContext, story: IStory) {
        let task = context.tasks.find((item) => item.id === story.taskId);

        // Fetch the task if it requires.
        if (!task && context.options.fetchTask) {
            task = await context.options.fetchTask({ id: story.taskId, stories: [story] });

            if (task) {
                context.tasks.push(task);
            }
        }

        if (!task) {
            return;
        }

        if (!task.stories) {
            return;
        }

        if (context.options.mapUsers) {
            await UserMapper.mapStoryAsync(story);
        }

        if (context.options.mapAttachments) {
            await ObjectStorageMapper.mapStoryAsync(story);
        }

        task.stories.push(story);

        return task;
    }

    protected async patchStory(context: TaskMutatorContext, patch: IMutationPatch): Promise<ITask | undefined> {
        if (!patch.id || !patch.taskId) {
            return;
        }

        const task = context.tasks.find((item) => item.id === patch.taskId);

        if (!task) {
            return;
        }

        if (!task.stories) {
            task.stories = [];
        }

        const carry = task.stories.find((item) => item.id === patch.id);

        if (carry) {
            diffPatcher.patch(carry, patch.patch);
        }

        return Promise.resolve(task);
    }

    protected deleteStory(context: TaskMutatorContext, story: IStory): Promise<ITask | undefined> {
        const task = context.tasks.find((item) => item.id === story.taskId);

        if (!task) {
            return Promise.resolve(undefined);
        }

        if (story.type === StoryType.Comment && typeof task.commentsCount === 'number') {
            task.commentsCount = task.commentsCount - 1;
        }

        if (story.type === StoryType.Attachment && typeof task.attachmentsCount === 'number') {
            task.attachmentsCount = task.attachmentsCount - 1;
        }

        if (!task.stories) {
            return Promise.resolve(undefined);
        }

        const index = task.stories.findIndex((item) => item.id === story.id);

        if (index !== -1) {
            task.stories.splice(index, 1);
        }

        return Promise.resolve(task);
    }

    protected async createApprovement(
        context: TaskMutatorContext,
        approvement: IApprovement,
    ): Promise<ITask | undefined> {
        return this.updateApprovement(context, approvement);
    }

    protected async updateApprovement(
        context: TaskMutatorContext,
        approvement: IApprovement,
    ): Promise<ITask | undefined> {
        const task = context.tasks.find((item) => item.id === approvement.taskId);

        if (!task) {
            return;
        }

        if (!task.approvements) {
            task.approvements = [];
        }

        const carry = task.approvements.find((item) => item.id === approvement.id);

        if (carry) {
            Object.assign(carry, approvement);
            return task;
        }

        if (context.options.mapUsers) {
            await UserMapper.mapApprovementAsync(approvement);
        }

        task.approvements.push(approvement);

        return task;
    }

    protected async patchApprovement(context: TaskMutatorContext, patch: IMutationPatch): Promise<ITask | undefined> {
        if (!patch.id || !patch.taskId) {
            return;
        }

        const task = context.tasks.find((item) => item.id === patch.taskId);

        if (!task) {
            return;
        }

        if (!task.approvements) {
            task.approvements = [];
        }

        const carry = task.approvements.find((item) => item.id === patch.id);

        if (carry) {
            diffPatcher.patch(carry, patch.patch);
            return task;
        }

        return task;
    }

    protected deleteApprovement(context: TaskMutatorContext, approvement: IApprovement): Promise<ITask | undefined> {
        const task = context.tasks.find((item) => item.id === approvement.taskId);

        if (!task?.approvements) {
            return Promise.resolve(undefined);
        }

        const index = task.approvements.findIndex((item) => item.id === approvement.id);

        if (index !== -1) {
            task.approvements.splice(index, 1);
        }

        return Promise.resolve(task);
    }

    protected async createCollaborator(
        context: TaskMutatorContext,
        collaborator: ICollaborator,
    ): Promise<ITask | undefined> {
        return this.updateCollaborator(context, collaborator);
    }

    protected async updateCollaborator(
        context: TaskMutatorContext,
        collaborator: ICollaborator,
    ): Promise<ITask | undefined> {
        let task = context.tasks.find((item) => item.id === collaborator.taskId);

        // Fetch the task if it requires.
        if (!task && context.options.fetchTask) {
            task = await context.options.fetchTask({ id: collaborator.taskId, collaborators: [collaborator] });

            if (task) {
                context.tasks.push(task);
            }
        }

        if (!task) {
            return;
        }

        if (!task.collaborators) {
            task.collaborators = [];
        }

        const carry = task.collaborators.find(
            (item) => item.userId === collaborator.userId && item.role === collaborator.role,
        );

        if (carry) {
            Object.assign(carry, collaborator);
            return task;
        }

        if (context.options.mapUsers) {
            await UserMapper.mapCollaboratorAsync(collaborator);
        }

        task.collaborators.push(collaborator);

        return task;
    }

    protected async patchCollaborator(): Promise<ITask | undefined> {
        return;
    }

    protected deleteCollaborator(context: TaskMutatorContext, collaborator: ICollaborator): Promise<ITask | undefined> {
        const task = context.tasks.find((item) => item.id === collaborator.taskId);

        if (!task) {
            return Promise.resolve(undefined);
        }

        if (!task?.collaborators) {
            return Promise.resolve(undefined);
        }

        const index = task.collaborators.findIndex(
            (item) => item.userId === collaborator.userId && item.role === collaborator.role,
        );

        if (index !== -1) {
            task.collaborators.splice(index, 1);
        }

        return Promise.resolve(task);
    }

    protected async createTaskSavePoint(
        context: TaskMutatorContext,
        savePoint: ITaskSavePoint,
    ): Promise<ITask | undefined> {
        const task = context.tasks.find((item) => item.id === savePoint.taskId);

        if (!task) {
            return;
        }

        if (typeof task.savePointsCount === 'number') {
            task.savePointsCount = task.savePointsCount + 1;
        }

        return undefined;
    }

    protected async updateTaskSavePoint(): Promise<ITask | undefined> {
        return undefined;
    }

    protected async deleteTaskSavePoint(
        context: TaskMutatorContext,
        savePoint: ITaskSavePoint,
    ): Promise<ITask | undefined> {
        const task = context.tasks.find((item) => item.id === savePoint.taskId);

        if (!task) {
            return;
        }

        if (typeof task.savePointsCount === 'number') {
            task.savePointsCount = task.savePointsCount - 1;
        }

        return undefined;
    }

    protected async patchTaskSavePoint(): Promise<ITask | undefined> {
        return undefined;
    }
}

const defaultMutator = new TaskMutator();

export class TaskMutatorContext implements IMutatorContext {
    public tasks: ITask[];
    public options: ITasksMutationContextOptions;
    public mutator: IMutator;

    constructor(
        tasks: ITask[],
        options: ITasksMutationContextOptions | null = null,
        mutator: IMutator = defaultMutator,
    ) {
        this.tasks = tasks;
        this.options = {
            mapUsers: false,
            mapParents: false,
            ...options,
        };
        this.mutator = mutator;
    }
}

export interface ITasksMutationContextOptions {
    mapUsers?: boolean;
    mapParents?: boolean;
    mapAttachments?: boolean;
    fetchTask?: (task: unknown) => Promise<ITask | undefined>;
    excludeTask?: (task: ITask) => boolean;
    ignoreTaskCreating?: (task: ITask) => boolean;
}

export default defaultMutator;
