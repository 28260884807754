import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "app-page-header__column app-page-header__column--tools" }
const _hoisted_2 = { class: "column__item" }
const _hoisted_3 = { class: "column column--padding-bottom" }
const _hoisted_4 = { class: "column__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VTaskFilter = _resolveComponent("VTaskFilter")!
  const _component_VSearchField = _resolveComponent("VSearchField")!
  const _component_VPageHeader = _resolveComponent("VPageHeader")!
  const _component_VGoalCard = _resolveComponent("VGoalCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_VDraggable = _resolveComponent("VDraggable")!
  const _component_VColumn = _resolveComponent("VColumn")!
  const _component_VButton = _resolveComponent("VButton")!
  const _component_VBoard = _resolveComponent("VBoard")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_openBlock(), _createBlock(_component_VPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_VPageHeader, {
        "show-third-block": "",
        title: _ctx.pageTitle
      }, {
        second: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_VTaskFilter, {
              class: "prevent-close",
              "filter-types": [
                            _ctx.TaskFilterTypes.Author,
                            _ctx.TaskFilterTypes.Status,
                            _ctx.TaskFilterTypes.Assignee,
                            _ctx.TaskFilterTypes.Contributor,
                            _ctx.TaskFilterTypes.AuthorOrAssignee,
                            _ctx.TaskFilterTypes.Deadline,
                            _ctx.TaskFilterTypes.CreationDate,
                        ],
              onChanged: _ctx.fetchData,
              modelValue: _ctx.filters,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event))
            }, null, 8, ["filter-types", "onChanged", "modelValue"]),
            _createVNode(_component_VSearchField, {
              class: "prevent-close",
              "debounce-mode": "",
              modelValue: _ctx.searchString,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchString) = $event)),
                _ctx.fetchData
              ]
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_VPageContent, { vertical: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_VBoard, {
            center: "",
            vertical: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                return (_openBlock(), _createBlock(_component_VColumn, {
                  key: columnIndex,
                  class: "column--goals column--shrink column--padding-bottom",
                  "no-scrolling": "",
                  "wheel-propagation": "",
                  column: column,
                  editable: _ctx.canUpdateColumnTitle(column),
                  onSetTitle: ($event: any) => (_ctx.setColumnTitle(column, $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_VDraggable, {
                      tag: "ul",
                      class: "column__list",
                      group: "tasks",
                      draggable: ".column__item",
                      "ghost-class": "task-card-ghost",
                      "item-key": "id",
                      "force-fallback": true,
                      "scroll-sensitivity": 200,
                      onAdd: ($event: any) => (_ctx.onMove(column, $event)),
                      onUpdate: ($event: any) => (_ctx.onMove(column, $event)),
                      modelValue: _ctx.groupedTasks[column.id],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.groupedTasks[column.id]) = $event)
                    }, {
                      item: _withCtx(({ element }) => [
                        _createElementVNode("li", _hoisted_2, [
                          _createVNode(_component_RouterLink, {
                            to: { name: 'goals.view', params: { goalId: element.id } }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_VGoalCard, { goal: element }, null, 8, ["goal"])
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["onAdd", "onUpdate", "modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["column", "editable", "onSetTitle"]))
              }), 128)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_VButton, {
                    class: "button--link-underline",
                    onClick: _ctx.createColumn
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('add-new-column')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}