class TasksPermissions {
    public readonly CanCreate = 'tasks.create';
    public readonly CanUpdate = 'tasks.update';
    public readonly CanDelete = 'tasks.delete';
    public readonly CanComment = 'tasks.comment';
    public readonly CanComplete = 'tasks.complete';
}

class ColumnsPermissions {
    public readonly CanCreate = 'columns.create';
    public readonly CanUpdate = 'columns.update';
    public readonly CanDelete = 'columns.delete';
}

class TaskManagementPermissions {
    public readonly CanRead = 'task-management.read';
}

export default class Permissions {
    public static readonly CanAll = '*';

    public static readonly Tasks = new TasksPermissions();
    public static readonly Columns = new ColumnsPermissions();
    public static readonly TaskManagement = new TaskManagementPermissions();
}
