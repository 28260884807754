import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["task-id"]
const _hoisted_2 = { class: "task-card__row task-card__row--semi-separate" }
const _hoisted_3 = { class: "task-card__row task-card__row--horizontal task-card__row--gap task-card__row--separate" }
const _hoisted_4 = {
  key: 0,
  class: "task-card__hint"
}
const _hoisted_5 = {
  key: 0,
  class: "task-card__counter"
}
const _hoisted_6 = { class: "task-card__hint task-card__hint--space-right" }
const _hoisted_7 = {
  key: 1,
  class: "task-card__hint"
}
const _hoisted_8 = {
  key: 2,
  class: "task-card__hint"
}
const _hoisted_9 = {
  key: 3,
  class: "task-card__hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VTaskStatus = _resolveComponent("VTaskStatus")!
  const _component_VUserCard = _resolveComponent("VUserCard")!
  const _component_TextSvg = _resolveComponent("TextSvg")!
  const _component_EmailSvg = _resolveComponent("EmailSvg")!
  const _component_AttachmentSvg = _resolveComponent("AttachmentSvg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["task-card prevent-close", {
            'task-card--expired': _ctx.isExpired,
            'task-card--finished': _ctx.isFinished,
            'task-card--selected': _ctx.isSelected,
        }]),
    "task-id": _ctx.task.id,
    onContextmenu: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openContextMenu && _ctx.openContextMenu(...args)))
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(["task-card__row task-card__title", {
                'task-card__title--blurry': !_ctx.task.title,
            }])
    }, _toDisplayString(_ctx.task.title ? _ctx.task.title : _ctx.$t('untitled')), 3),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VTaskStatus, { task: _ctx.task }, null, 8, ["task"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.contributors.length > 0 && _ctx.contributors[0].user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_VUserCard, {
              user: _ctx.contributors[0].user,
              "picture-only": "",
              tiny: ""
            }, null, 8, ["user"]),
            (_ctx.contributors.length > 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " +" + _toDisplayString(_ctx.contributors.length - 1), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formattedDeadline ? _ctx.formattedDeadline : _ctx.$t('no-deadline')), 1),
      (_ctx.task.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_TextSvg)
          ]))
        : _createCommentVNode("", true),
      (_ctx.numberOfComments > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_EmailSvg),
            _createTextVNode(" " + _toDisplayString(_ctx.numberOfComments), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.numberOfAttachments > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_AttachmentSvg)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 42, _hoisted_1))
}