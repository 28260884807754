
// Svg
import TimesSvg from '@/assets/times.svg';
import DownloadSvg from '@/assets/download.svg';

// Components
import VButton from './VButton.vue';

// Other
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import ShowImageContext, { ShowImageEvent } from '@/core/Values/ShowImageContext';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        TimesSvg,
        DownloadSvg,

        VButton,
    },

    data: () => ({
        visible: false,
        context: null as ShowImageContext | null,
    }),

    methods: {
        open(event: ShowImageEvent) {
            this.visible = true;
            this.context = event;

            this.applyModalStyles();
        },

        close(): void {
            this.visible = false;
            this.context = null;
            this.applyModalStyles();
        },

        applyModalStyles() {
            if (this.visible && !document.body.classList.contains('v-modal-opened')) {
                if (this.$refs.modal) {
                    (this.$refs.modal as HTMLDivElement).focus();
                }
                document.body.classList.add('v-modal-opened');
            } else {
                document.body.classList.remove('v-modal-opened');
            }
        },
    },

    mounted(): void {
        this.applyModalStyles();
    },

    created(): void {
        emitter.on(EventNames.ShowImage, this.open);
    },
});
