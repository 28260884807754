import Methods from '../Methods';
import ITask from '../Models/ITask';
import IMutation from '../Mutations/IMutation';
import Transport, { ITransport } from '../Transports';
import CollaboratorRole from '../Values/CollaboratorRole';
import IAttachment from '../Values/IAttachment';
import TaskType from '../Values/TaskType';

type TaskIncludes =
    | 'stories'
    | 'approvements'
    | 'collaborators'
    | 'comments-count'
    | 'attachments-count'
    | 'save-points-count';

export interface QueryTaskRequest {
    page?: number;
    perPage?: number;
    search?: string;
    whereId?: string | number | Array<string | number>;
    whereType?: string | number | Array<string | number>;
    whereTitle?: string | number | Array<string | number>;
    whereTitleNot?: string | number | Array<string | number>;
    whereStatus?: string | number | Array<string | number>;
    whereTaskId?: string | number | Array<string | number>;
    whereColumnId?: string | number | Array<string | number>;
    whereBoardId?: string | number | Array<string | number>;
    whereProjectId?: string | number | Array<string | number>;
    includes?: TaskIncludes[];
    whereAuthor?: string | string[];
    whereAssignee?: string | string[];
    whereContributor?: string | string[];
    whereCollaborator?: string | string[];
    whereAuthorOrAssignee?: string | string[];
    whereApprovementRequired?: boolean;
    whereCreatedBetween?: string[];
    whereDeadlineBetween?: string[];
    withArchived?: boolean;
}

export interface CreateCommentRequest {
    text: string;
    attachments: Array<IAttachment>;
}

class TaskService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public queryAsync(context: QueryTaskRequest): Promise<ITask[]> {
        return this._transport.invokeAsync<QueryTaskRequest, ITask[]>(Methods.Get, `/api/v1/tasks`, context);
    }

    public findAsync(taskId: number): Promise<ITask> {
        return this._transport.invokeAsync<undefined, ITask>(Methods.Get, `/api/v1/tasks/${taskId}`);
    }

    public createAsync(type: TaskType, parentId: number | null = null): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ type: TaskType; parentId: number | null }, IMutation[]>(
            Methods.Post,
            '/api/v1/tasks',
            {
                type,
                parentId,
            },
        );
    }

    public createCommentAsync(taskId: number, comment: CreateCommentRequest): Promise<IMutation[]> {
        return this._transport.invokeAsync<CreateCommentRequest, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/comments`,
            {
                text: comment.text,
                attachments: comment.attachments,
            },
        );
    }

    public updateCommentAsync(taskId: number, commentId: number, text: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ text: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/comments/${commentId}`,
            {
                text,
            },
        );
    }

    public deleteStoryAsync(taskId: number, storyId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(
            Methods.Delete,
            `/api/v1/tasks/${taskId}/stories/${storyId}`,
        );
    }

    public createAttachmentAsync(taskId: number, objectName: string, fileName: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ objectName: string; fileName: string }, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/attachments`,
            {
                fileName,
                objectName,
            },
        );
    }

    public setTitleAsync(taskId: number, title: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ title: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/title`,
            {
                title,
            },
        );
    }

    public setParentAsync(taskId: number, parentId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ parentId: number }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/parent`,
            {
                parentId,
            },
        );
    }

    public setDeadlineAsync(taskId: number, deadline: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ deadline: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/deadline`,
            {
                deadline,
            },
        );
    }

    public setAuthorAsync(taskId: number, authorId: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ authorId: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/author`,
            {
                authorId,
            },
        );
    }

    public moveAsync(taskId: number, columnId: number, rank: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/move`, {
            rank,
            columnId,
        });
    }

    public setDescriptionAsync(taskId: number, description: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ description: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/description`,
            {
                description,
            },
        );
    }

    public addCollaboratorAsync(taskId: number, collaboratorId: string, role: CollaboratorRole): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ collaboratorId: string; role: CollaboratorRole }, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/collaborators`,
            {
                collaboratorId,
                role,
            },
        );
    }

    public removeCollaboratorAsync(
        taskId: number,
        collaboratorId: string,
        role: CollaboratorRole,
    ): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ role: CollaboratorRole }, IMutation[]>(
            Methods.Delete,
            `/api/v1/tasks/${taskId}/collaborators/${collaboratorId}`,
            {
                role,
            },
        );
    }

    public startApprovementProcessAsync(taskId: number, approverIds: string[]): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ approverIds: string[] }, IMutation[]>(
            Methods.Post,
            `/api/v1/tasks/${taskId}/approvements`,
            {
                approverIds,
            },
        );
    }

    public completeAsync(taskId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/complete`);
    }

    public finishAsync(taskId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/finish`);
    }

    public toDraftAsync(taskId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/draft`);
    }

    public toInProgressAsync(taskId: number, comment?: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ comment?: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/in-progress`,
            {
                comment,
            },
        );
    }

    public archiveAsync(taskId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<unknown, IMutation[]>(Methods.Patch, `/api/v1/tasks/${taskId}/archive`);
    }

    public approveAsync(taskId: number, approvementId: number, comment?: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ comment?: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/approvements/${approvementId}/approve`,
            {
                comment,
            },
        );
    }

    public disapproveAsync(taskId: number, approvementId: number, comment?: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ comment?: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/approvements/${approvementId}/disapprove`,
            {
                comment,
            },
        );
    }

    public rollbackChangesAsync(taskId: number, comment?: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ comment?: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/tasks/${taskId}/rollback-changes`,
            {
                comment,
            },
        );
    }
}

const service = new TaskService(Transport);

Object.freeze(service);

export default service;
