
// Svg
import PlusSvg from '@/assets/plus.svg';
import TimesSvg from '@/assets/times.svg';
import FilterSvg from '@/assets/filter.svg';

// Components
import VPopper from 'vue3-popper';
import VButton from './VButton.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VGridRow from './VGridRow.vue';
import VGridColumn from './VGridColumn.vue';
import VGridContainer from './VGridContainer.vue';
import VSelect from './VSelect.vue';
import VCheckbox from './VCheckbox.vue';
import VDatePicker from './VDatePicker.vue';
import VUserSelectorSimple from './VUserSelectorSimple.vue';

// Other
import { defineComponent, Component, markRaw, ref } from 'vue';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import IUser from '@/core/Models/IUser';
import { QueryTaskRequest } from '@/core/Services/TaskService';
import Status from '@/core/Values/Status';
import kebabCase from 'lodash.kebabcase';
import { PropType } from 'vue';
import { required } from '@/utils/utils';
import { useI18n } from 'vue-i18n';

export interface IFilter extends IDropdownOption {
    type: TaskFilterTypes;
    on?: Record<string, () => void>;
    attrs?: Record<string, unknown>;
    modelValue: unknown;
    format: (value: unknown) => Partial<QueryTaskRequest>;
    component: Component;
}

export enum TaskFilterTypes {
    Author = 1,
    Assignee,
    Contributor,
    Status,
    Deadline,
    CreationDate,
    AuthorOrAssignee,
    WithArchived,
}

export type FilterOption = { [key: number]: IFilter };
export type FilterModelValue = { value: Partial<IFilter>[]; formattedValue: Partial<QueryTaskRequest> };

export default defineComponent({
    components: {
        PlusSvg,
        TimesSvg,
        FilterSvg,

        VPopper,
        VButton,
        VButtonDropdown,
        VDatePicker,
        VCheckbox,
        VGridRow,
        VGridColumn,
        VGridContainer,
        VUserSelectorSimple,
    },

    setup(props) {
        const { t } = useI18n();

        const showArchived = ref(props.modelValue.formattedValue?.withArchived ?? false);
        const filters = ref([] as IFilter[]);
        const filterOptions = ref({} as FilterOption);

        return { t, showArchived, filters, filterOptions };
    },

    props: {
        modelValue: {
            type: Object as PropType<FilterModelValue>,
            required: true,
            default: () => ({}),
        },
        filterTypes: { type: Array as PropType<TaskFilterTypes[]>, required: true, default: () => [] },
    },

    computed: {
        filtersCount(): number {
            // filters count + (number)showArchived (1 or 0)
            return (
                Object.values(this.filters).filter((filter) => required(filter.modelValue)).length + +this.showArchived
            );
        },

        displayFilterOptions(): IDropdownOption[] {
            return Object.values(this.filterOptions).filter(
                (option) => !this.filters.find((filter) => option.type === filter.type),
            );
        },
    },

    methods: {
        clear() {
            this.filters = [];
            this.showArchived = false;
            this.updateModelValue();
        },

        addFilter(option: IDropdownOption) {
            const filter = option as IFilter;

            if (this.filters.indexOf(filter) !== -1) {
                return;
            }

            this.filters.push({ ...filter });
            this.updateModelValue();
        },

        getModelValue(): Partial<FilterModelValue> {
            const value = this.filters.map((filter) => ({ type: filter.type, modelValue: filter.modelValue }));
            const formattedValue = this.filters.reduce(
                (carry, filter) => Object.assign(carry, filter.format(filter.modelValue)),
                {} as Record<string, unknown>,
            );

            if (this.showArchived) {
                value.push({ type: TaskFilterTypes.WithArchived, modelValue: this.showArchived });
                formattedValue.withArchived = this.showArchived;
            }

            return {
                value,
                formattedValue,
            };
        },

        updateModelValue(changed = true) {
            this.$nextTick(() => {
                const modelValue = this.getModelValue();
                this.$emit('update:modelValue', modelValue);

                if (changed) {
                    this.$emit('changed', modelValue);
                }
            });
        },

        calculateFilters() {
            if (!this.modelValue?.value) {
                this.filters = [];
                this.updateModelValue(false);
                return;
            }

            this.filters = this.modelValue.value.reduce((carry, filter) => {
                const key = filter.type as number;

                if (this.filterOptions[key]) {
                    carry.push({ ...this.filterOptions[key], ...filter });
                }

                return carry;
            }, [] as IFilter[]);
        },
    },

    watch: {
        modelValue: {
            handler: 'calculateFilters',
        },
    },

    created() {
        const options: IFilter[] = [
            {
                type: TaskFilterTypes.Author,
                action: this.addFilter,
                title: this.t('author'),
                on: {
                    input: this.updateModelValue,
                },
                attrs: {
                    multiple: true,
                },
                modelValue: [],
                format: (value: unknown) => (value ? { whereAuthor: (value as IUser[])?.map((item) => item.id) } : {}),
                component: markRaw(VUserSelectorSimple),
            },
            {
                type: TaskFilterTypes.Assignee,
                action: this.addFilter,
                title: this.t('assignee'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    multiple: true,
                },
                modelValue: [],
                format: (value: unknown) =>
                    value ? { whereAssignee: (value as IUser[])?.map((item) => item.id) } : {},
                component: markRaw(VUserSelectorSimple),
            },
            {
                type: TaskFilterTypes.Contributor,
                action: this.addFilter,
                title: this.t('contributor'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    multiple: true,
                },
                modelValue: [],
                format: (value: unknown) =>
                    value ? { whereContributor: (value as IUser[])?.map((item) => item.id) } : {},
                component: markRaw(VUserSelectorSimple),
            },
            {
                type: TaskFilterTypes.AuthorOrAssignee,
                action: this.addFilter,
                title: this.t('author') + '/' + this.t('assignee'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    multiple: true,
                },
                modelValue: [],
                format: (value: unknown) =>
                    value ? { whereAuthorOrAssignee: (value as IUser[])?.map((item) => item.id) } : {},
                component: markRaw(VUserSelectorSimple),
            },
            {
                type: TaskFilterTypes.Status,
                action: this.addFilter,
                title: this.t('status'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    label: 'title',
                    class: 'v-select--primary v-select--visible',
                    multiple: true,
                    placeholder: this.t('status'),
                    options: Object.keys(Status)
                        .filter((key) => !isNaN(parseInt(key)))
                        .map((key) => {
                            const status = parseInt(key);

                            return {
                                title: this.t('statuses.' + kebabCase(Status[status])),
                                value: status,
                            };
                        }),
                },
                modelValue: [],
                format: (value: unknown) =>
                    value ? { whereStatus: (value as Array<{ value: number }>)?.map((item) => item.value) } : {},
                component: markRaw(VSelect),
            },
            {
                type: TaskFilterTypes.Deadline,
                action: this.addFilter,
                title: this.t('deadline'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    config: {
                        range: true,
                        format: 'dd.MM.yyyy',
                    },
                    class: 'date-picker--outline',
                },
                modelValue: '',
                format: (value) => (!value ? {} : { whereDeadlineBetween: value as string[] }),
                component: markRaw(VDatePicker),
            },
            {
                type: TaskFilterTypes.CreationDate,
                action: this.addFilter,
                title: this.t('creation-date'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    config: {
                        range: true,
                        format: 'dd.MM.yyyy',
                    },
                    class: 'date-picker--outline',
                },
                modelValue: '',
                format: (value) => (!value ? {} : { whereCreatedBetween: value as string[] }),
                component: markRaw(VDatePicker),
            },
        ];

        this.filterOptions = options.reduce((carry, option) => {
            if (this.filterTypes.includes(option.type)) {
                carry[option.type] = option;
            }

            return carry;
        }, {} as FilterOption);
        this.calculateFilters();
    },
});
