import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BottomArrowSvg = _resolveComponent("BottomArrowSvg")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!

  return (_openBlock(), _createBlock(_component_BaseSelect, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), _createSlots({
    "open-indicator": _withCtx(({ attributes }) => [
      _createVNode(_component_BottomArrowSvg, _normalizeProps(_guardReactiveProps(attributes)), {
        default: _withCtx(() => [
          _createTextVNode("^")
        ]),
        _: 2
      }, 1040)
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)))
        ])
      }
    })
  ]), 1040))
}