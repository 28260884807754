import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-field" }
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchSvg = _resolveComponent("SearchSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchSvg, { class: "search-field__icon" }),
    _withDirectives(_createElementVNode("input", {
      class: "input-field input-field--primary input-field--bordered search-field__input",
      type: "text",
      "aria-placeholder": "search",
      placeholder: _ctx.$t('search'),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.value]
    ])
  ]))
}