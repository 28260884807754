import IProject from '@/core/Models/IProject';
import IUserSettings from '@/core/Values/IUserSettings';
import { createStore } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import IUser from '@/core/Models/IUser';
import ApplicationState from '@/core/Values/ApplicationState';
import IBoard from '@/core/Models/IBoard';

export interface IState {
    appState: ApplicationState;
    appError?: string | unknown;

    user: IUser | null;
    userSettings: IUserSettings;
    userPermissions: string[];

    usersMap: Map<string, IUser>;
    objectStorageMap: Map<string, string>;
    boards: IBoard[] | null;
    projects: IProject[] | null;
    storageProxy: Map<string, unknown>;
}

const state: IState = {
    appError: null,
    appState: ApplicationState.Loading,
    user: null,
    userSettings: {
        locale: 'ru',
    },
    userPermissions: [],
    usersMap: new Map<string, IUser>(),
    objectStorageMap: new Map<string, string>(),
    boards: null,
    projects: null,
    storageProxy: new Map<string, unknown>(),
};

export default createStore({
    state,
    getters,
    mutations,
    actions,
});
