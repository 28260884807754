import store from '@/store';
import { IStorage } from './IStorage';
import { MutationNames } from '@/store/mutations';

/**
 * Session Storage saves data until the page is reloaded.
 */
export default class SessionStorage implements IStorage {
    public static Instance = new SessionStorage();

    computed<TStoreType>(key: string, defaultValue: TStoreType) {
        return {
            get: () => this.getOrDefault(key, defaultValue),
            set: (value: TStoreType) => this.set(key, value),
        };
    }

    get<TStoreType>(key: string): TStoreType | null {
        return store.state.storageProxy.get(key) as TStoreType | null;
    }

    getOrDefault<TStoreType>(key: string, defaultValue: TStoreType): TStoreType {
        const value = store.state.storageProxy.get(key) as TStoreType | null;

        if (!value) {
            this.set(key, defaultValue);
            return store.state.storageProxy.get(key) as TStoreType;
        }

        return value;
    }

    set<TStoreType>(key: string, value: TStoreType) {
        store.commit(MutationNames.StorageProxySet, { key, value });
    }
}
