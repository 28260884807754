import IMutation from '../Mutations/IMutation';
import ObjectType from '../Mutations/ObjectType';
import { IMutator } from '../Mutations/IMutator';
import IMutatorContext from '../Mutations/IMutatorContext';
import { EventNames } from '../EventNames';
import INotification from '../Models/INotification';
import IMutationPatch from '../Mutations/IMutationPatch';
import MutationBus from '../Mutations/MutationBus';
import NotificationService from '../Services/NotificationService';
import { ref, Ref } from 'vue';

export class CounterNotificationMutator implements IMutator {
    private _matrix = {
        [ObjectType.Notification]: {
            [EventNames.NotificationRead]: this.readNotification,
            [EventNames.NotificationCreated]: this.createNotification,
            [EventNames.NotificationDeleted]: this.deleteNotification,
        },
    };

    public mutate(context: IMutatorContext, mutations: IMutation[], eventName: string) {
        mutations.forEach((mutation) => {
            const objectType = mutation.objectType as keyof typeof this._matrix;

            if (
                !Object.prototype.hasOwnProperty.call(this._matrix, mutation.objectType) ||
                !Object.prototype.hasOwnProperty.call(this._matrix[objectType], eventName)
            ) {
                return;
            }

            const action = this._matrix[objectType][eventName] as (
                context: CounterNotificationMutatorContext,
                objectState: unknown,
            ) => void;

            action.bind(this)(context as CounterNotificationMutatorContext, mutation.objectState);
        });
    }

    private createNotification(context: CounterNotificationMutatorContext) {
        context.counter.value = context.counter.value + 1;
    }

    private readNotification(context: CounterNotificationMutatorContext, patch: IMutationPatch) {
        const changes = patch.patch as { readAt?: string[] };
        if (
            Object.prototype.hasOwnProperty.call(changes, 'readAt') &&
            Array.isArray(changes.readAt) &&
            changes.readAt.length > 1 &&
            changes.readAt[1]
        ) {
            context.counter.value = Math.max(context.counter.value - 1, 0);
        }
    }

    private deleteNotification(context: CounterNotificationMutatorContext, notification: INotification) {
        if (!notification.readAt) {
            context.counter.value = Math.max(context.counter.value - 1, 0);
        }
    }
}

const defaultMutator = new CounterNotificationMutator();

export class CounterNotificationMutatorContext implements IMutatorContext {
    public counter: Ref<number>;
    public mutator: IMutator;
    private _initialized = false;

    public constructor(mutator: IMutator = defaultMutator) {
        this.counter = ref(0);
        this.mutator = mutator;
    }

    initialize() {
        if (this._initialized) {
            return;
        }

        this._initialized = true;

        NotificationService.countAsync({
            whereUnread: true,
        }).then((count) => (this.counter.value = count));
    }
}

const context = new CounterNotificationMutatorContext();

MutationBus.activate(context);

export default context;
