import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VAppError = _resolveComponent("VAppError")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_VPane = _resolveComponent("VPane")!
  const _component_VSplitpanes = _resolveComponent("VSplitpanes")!
  const _component_VAppLayout = _resolveComponent("VAppLayout")!
  const _component_VModalError = _resolveComponent("VModalError")!
  const _component_VModalAlert = _resolveComponent("VModalAlert")!
  const _component_VModalConfirm = _resolveComponent("VModalConfirm")!
  const _component_VModalImageViewer = _resolveComponent("VModalImageViewer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_VAppError, {
          key: 0,
          error: _ctx.error
        }, null, 8, ["error"]))
      : _createCommentVNode("", true),
    (_ctx.isReady)
      ? (_openBlock(), _createBlock(_component_VAppLayout, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_VSplitpanes, {
              onResize: _cache[0] || (_cache[0] = ($event: any) => (_ctx.panelWidth = $event[1].size))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_VPane, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterView)
                  ]),
                  _: 1
                }),
                _createVNode(_component_RouterView, { name: "taskViewer" })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_VModalError),
    _createVNode(_component_VModalAlert),
    _createVNode(_component_VModalConfirm),
    _createVNode(_component_VModalImageViewer)
  ], 64))
}