import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-page app-page--no-shrink app-page--panel prevent-close" }
const _hoisted_2 = {
  key: 0,
  class: "home-task-panel__content home-task-panel__content--center"
}
const _hoisted_3 = {
  key: 1,
  class: "home-task-panel__content home-task-panel__content--center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VLoader = _resolveComponent("VLoader")!
  const _component_NoDataSvg = _resolveComponent("NoDataSvg")!
  const _component_NoDataDarkSvg = _resolveComponent("NoDataDarkSvg")!
  const _component_VIllustration = _resolveComponent("VIllustration")!
  const _component_VTaskPanel = _resolveComponent("VTaskPanel")!
  const _component_VPane = _resolveComponent("VPane")!

  return (_ctx.visible)
    ? (_openBlock(), _createBlock(_component_VPane, {
        key: 0,
        class: _normalizeClass(["splitpanes__pane--sidebar splitpanes__pane--shadow", {
            'splitpanes__pane--fixed': _ctx.$device.isMobile,
        }]),
        "min-size": "35",
        "max-size": "80",
        size: _ctx.$device.isMobile ? 100 : _ctx.panelWidth
      }, {
        default: _withCtx(() => [
          _createElementVNode("aside", _hoisted_1, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_VLoader, { class: "loader--mini loader--center" })
                ]))
              : (!_ctx.task)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_VIllustration, {
                      tip: _ctx.$t('task-not-found')
                    }, {
                      default: _withCtx(() => [
                        (_ctx.theme === 'light')
                          ? (_openBlock(), _createBlock(_component_NoDataSvg, { key: 0 }))
                          : (_ctx.theme === 'dark')
                            ? (_openBlock(), _createBlock(_component_NoDataDarkSvg, { key: 1 }))
                            : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["tip"])
                  ]))
                : (_openBlock(), _createBlock(_component_VTaskPanel, {
                    key: 2,
                    task: _ctx.task,
                    project: _ctx.project,
                    board: _ctx.board,
                    parent: _ctx.parent,
                    subtasks: _ctx.subtasks,
                    "can-create-sub-tasks": _ctx.canCreateSubTasks,
                    uploader: _ctx.uploader,
                    onClose: _ctx.close
                  }, null, 8, ["task", "project", "board", "parent", "subtasks", "can-create-sub-tasks", "uploader", "onClose"]))
          ])
        ]),
        _: 1
      }, 8, ["class", "size"]))
    : _createCommentVNode("", true)
}