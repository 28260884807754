
// Svg
import DotsSvg from '@/assets/dots.svg';
import PlusSvg from '@/assets/plus.svg';
import TimesSvg from '@/assets/times.svg';
import NoticeSvg from '@/assets/notice.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VButton from './VButton.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VFileList from './VFileList.vue';
import VTaskCardInline from './VTaskCardInline.vue';
import VChatInput from './VChatInput.vue';
import VFeedStory from './VFeedStory.vue';
import VUsersList from './VUsersList.vue';
import VDatePicker from './VDatePicker.vue';
import VInlineLink from './VInlineLink.vue';
import VTaskPanelLayer from './VTaskPanelLayer.vue';
import VApprovementList from './VApprovementList.vue';
import VUserSelector from './VUserSelector.vue';
import VCollaboratorsList from './VCollaboratorsList.vue';
import VTextarea from './VTextarea.vue';
import VContentEditable from './VContentEditable.vue';
import VSelect from './VSelect.vue';

// Other
import ITask from '../core/Models/ITask';
import IUser from '@/core/Models/IUser';
import StoryType from '@/core/Values/StoryType';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import IAttachment from '@/core/Values/IAttachment';
import { fileExtension } from '@/utils/utils';
import { debounce } from 'debounce';
import IProject from '@/core/Models/IProject';
import IBoard from '@/core/Models/IBoard';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import TaskType from '@/core/Values/TaskType';
import IUploader from '@/core/Uploader/IUploader';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import IApiResult from '@/core/IApiResult';
import { defineComponent, ref } from 'vue';
import { PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import StoryViewMode, { viewModeOptions } from '@/core/Values/StoryViewMode';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import UITaskMixin from '../mixins/UITaskMixin';

interface CollaboratorsModalContext {
    role: CollaboratorRole;
    taskId: number;
    multiple: boolean;
}

interface ISelectOption {
    title: string;
    value: number;
}
type DebounceFunction = ((...params: unknown[]) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {
        DotsSvg,
        PlusSvg,
        TimesSvg,
        NoticeSvg,

        VTaskStatus,
        VButton,
        VButtonDropdown,
        VSelect,
        VFileList,
        VTaskPanelLayer,
        VTaskCardInline,
        VChatInput,
        VUsersList,
        VFeedStory,
        VDatePicker,
        VInlineLink,
        VUserSelector,
        VApprovementList,
        VCollaboratorsList,
        VTextarea,
        VContentEditable,
    },

    setup(props) {
        const formErrors = ref(null as Record<string, string> | null);
        const formErrorsDebounce = ref(null as DebounceFunction | null);

        const showApproversLayer = ref(false);
        const showApproversLayerApprovers = ref([] as IUser[]);

        const mixin = UITaskMixin(
            ref(props.task),
            (callback?: () => void): void => {
                formErrors.value = mixin.validateTaskFields(mixin.taskOrGoal.value);

                if (!formErrors.value) {
                    callback?.();
                    formErrorsDebounce.value?.flush();
                    formErrorsDebounce.value = null;
                    return;
                }

                if (!formErrorsDebounce.value) {
                    formErrorsDebounce.value = debounce(
                        () => (formErrors.value = formErrorsDebounce.value = null),
                        3000,
                    ); // 3s
                }

                formErrorsDebounce.value?.();
            },
            (): void => {
                showApproversLayer.value = true;
                showApproversLayerApprovers.value = mixin.taskOrGoal.value.approvements?.length
                    ? mixin.taskOrGoal.value.approvements
                          ?.filter((approvement) => approvement.approver)
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .map((collaborator) => collaborator.approver!) ?? []
                    : mixin.taskOrGoal.value.collaborators
                          ?.filter(
                              (collaborator) => collaborator.user && collaborator.role === CollaboratorRole.Assignee,
                          )
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .map((collaborator) => collaborator.user!) ?? [];
            },
        );

        return {
            ...mixin,
            formErrors,
            formErrorsDebounce,
            showApproversLayer,
            showApproversLayerApprovers,
        };
    },

    props: {
        task: { type: Object as PropType<ITask>, required: true },
        parent: { type: Object as PropType<ITask | null>, default: null },
        project: { type: Object as PropType<IProject | null>, default: null },
        subtasks: { type: Array as PropType<ITask[] | null>, default: null },
        uploader: { type: Object as PropType<IUploader<IApiResult<IObjectStoreModel>>>, required: true },
        board: { type: Object as PropType<IBoard>, default: null },
        canCreateSubTasks: { type: Boolean, default: false },
    },

    data: () => ({
        parentOptions: [] as ISelectOption[],

        viewModeOptions,

        showAuthorLayer: false,
        showAuthorLayerAuthors: [] as IUser[],

        showCollaboratorsLayer: false,
        showCollaboratorsLayerContext: null as CollaboratorsModalContext | null,

        fetchParentsOptionsDebounce: null as
            | (((search: string, loading: (isLoading: boolean) => void) => Promise<void>) & { clear(): void } & {
                  flush(): void;
              })
            | null,
    }),

    computed: {
        storyViewMode: Storages.Settings.computed(Settings.UI.StoryViewMode, StoryViewMode.All),

        isSubtask(): boolean {
            return this.task.type === TaskType.Subtask;
        },

        assignees(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Assignee);
        },

        contributors(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Contributor);
        },

        collaborators(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Collaborator);
        },

        attachments(): IAttachment[] {
            if (!this.task.stories) {
                return [];
            }

            return this.task.stories
                .filter((story) => story.type === StoryType.Attachment)
                .map(
                    (attachment): IAttachment => ({
                        id: attachment.id,
                        authorId: attachment.actorId,
                        fileName: attachment.fileName,
                        objectName: attachment.objectName,
                        extension: fileExtension(attachment.fileName),
                        downloadUri: attachment.downloadUri,
                    }),
                );
        },

        parentTitle(): string {
            return !this.parent || this.parent.type === TaskType.Goal ? this.t('goal', 1) : this.t('task', 1);
        },

        parentLink(): RouteLocationRaw | null {
            if (!this.parent) {
                return null;
            }

            return this.getTaskRouteLocation(this.parent);
        },
    },

    methods: {
        startApprovementProcess(approvers: IUser[]): void {
            this.showApproversLayer = false;

            this.startApprovementProcessAsync(
                this.task,
                approvers.map((approver) => approver.id),
            );
        },

        hideCollaborators(): void {
            this.showCollaboratorsLayerContext = null;
        },

        hideAuthorSelector(): void {
            this.showAuthorLayer = false;
            this.showAuthorLayerAuthors = [];
        },

        showAuthorSelector(): void {
            if (!this.task.author) {
                return;
            }

            this.showAuthorLayer = true;
            this.showAuthorLayerAuthors = [this.task.author];
        },

        hideApproverSelector(): void {
            this.showApproversLayer = false;
            this.showApproversLayerApprovers = [];
        },

        showMangers(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Assignee, false);
        },

        showContributors(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Contributor);
        },

        showCollaborators(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Collaborator);
        },

        showCollaboratorsWithRole(role: CollaboratorRole, multiple = true): void {
            this.showCollaboratorsLayer = true;
            this.showCollaboratorsLayerContext = {
                taskId: this.task.id,
                role: role,
                multiple,
            };
        },

        getOptionValue(option: { value: number }): number {
            return option.value;
        },

        getCollaboratorsWithRole(role: CollaboratorRole): IUser[] {
            return (
                this.task.collaborators?.reduce((result, collaborator) => {
                    if (collaborator.user && collaborator.role === role) {
                        result.push(collaborator.user);
                    }

                    return result;
                }, [] as IUser[]) ?? []
            );
        },

        setAuthor(authors: IUser[]): void {
            if (authors.length <= 0) {
                return;
            }

            this.showAuthorLayer = false;
            this.changeAuthorAsync(this.task, authors[0].id);
        },

        async fetchParentsAsync(search = ''): Promise<void> {
            if (this.task.type === TaskType.Goal) {
                return;
            }

            let query: QueryTaskRequest = {
                page: 1,
                perPage: 10,
                search,
                whereTitleNot: '',
                whereType: this.task.type == TaskType.Task ? TaskType.Goal : TaskType.Subtask,
            };

            const parents = await TaskService.queryAsync(query);

            this.parentOptions = parents.map((parent) => ({
                value: parent.id,
                title: parent.title,
            }));
        },

        fetchParentsOptions(search: string, loading: (isLoading: boolean) => void): void {
            if (!this.fetchParentsOptionsDebounce) {
                this.fetchParentsOptionsDebounce = debounce(
                    async (search: string, loading: (isLoading: boolean) => void) => {
                        loading(true);
                        try {
                            await this.fetchParentsAsync(search);
                        } finally {
                            loading(false);
                            this.fetchParentsOptionsDebounce = null;
                        }
                    },
                    500,
                );
            }

            this.fetchParentsOptionsDebounce(search, loading);
        },

        fetchParentsOptionsIfNotExistsOrStartEdit(): void {
            if (!this.editable) {
                return this.confirmToDraft();
            }

            if (!this.parentOptions.length) {
                this.fetchParentsAsync();
            }
        },

        applyRouteAction(): void {
            const action = this.$route.query.action;

            switch (action) {
                case 'validate':
                    this.validate();
                    break;
                case 'select-approvers':
                    this.showApproverSelector();
                    break;
            }

            if (action) {
                this.$router.replace({ query: { ...this.$route.query, action: undefined } });
            }
        },
    },

    watch: {
        'task'() {
            const taskPanel = document.querySelector('.task-panel__scroll') as HTMLElement;
            if (taskPanel) {
                taskPanel.scrollTop = 0;
            }
        },
        '$route.query.action': 'applyRouteAction',
    },

    mounted() {
        this.applyRouteAction();
    },
});
