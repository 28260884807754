import escapeStringRegexp from 'escape-string-regexp';

function hasParentWithClassNameString(element: HTMLElement, className: string): boolean {
    // Enumerate all parent elements and if one of the parents contains the `prevent-close` class, we stop the hide operation.
    while (element.parentNode) {
        if (element.className === 'string' && element.className.indexOf(className) !== -1) {
            return true;
        }

        element = element.parentNode as HTMLElement;
    }

    return false;
}

function hasParentWithClassNameArray(element: HTMLElement, classNames: string[]): boolean {
    let pattern = '';
    let className = classNames.pop();
    while (className) {
        pattern = pattern + '|' + escapeStringRegexp(className);
        className = classNames.pop() ?? '';
    }

    pattern = pattern.substring(1, pattern.length);

    if (!pattern) {
        return false;
    }

    // Enumerate all parent elements and if one of the parents contains the `prevent-close` class, we stop the hide operation.
    while (element.parentNode) {
        if (typeof element.className === 'string' && element.className.match(pattern)) {
            return true;
        }

        element = element.parentNode as HTMLElement;
    }

    return false;
}

export function hasParentWithClassName(element: HTMLElement, className: string | string[]): boolean {
    return typeof className === 'string'
        ? hasParentWithClassNameString(element, className as string)
        : hasParentWithClassNameArray(element, className as string[]);
}

const focusElements = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])',
];

export function focusOnFirstTarget(container: HTMLElement): void {
    if (container.matches(focusElements as unknown as string)) {
        container.focus();
        return;
    }

    // Force cast to use the modern method.
    const focusElement = container?.querySelector(focusElements as unknown as string) as HTMLElement | null;

    if (focusElement) {
        focusElement.focus();
    }
}
