
// Components

// Other
import debounce from 'debounce';
import { markRaw, ref } from 'vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

type DebounceFunction = ((value: string) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {},

    props: {
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        editable: { type: Boolean, default: true },
        maxlength: { type: [Number, String], default: 0 },
        modelValue: { type: String, required: true },
        placeholder: { type: String, default: '' },
        trimNewLines: { type: Boolean, default: true },
        debounceMode: { type: Boolean, default: false },
        debounceInterval: { type: Number, default: 500 },
        concurrencyMode: { type: Boolean, default: false },
    },

    setup() {
        const { t } = useI18n();

        return {
            t,
            value: ref(''),
            focused: ref(false),
            debounce: ref(null as DebounceFunction | null),
        };
    },

    methods: {
        focus(): void {
            if (!this.$refs.textarea) {
                return;
            }

            (this.$refs.textarea as HTMLTextAreaElement).focus();
        },

        onBlur(): void {
            this.focused = false;
            this.debounce?.flush();
            this.debounce = null;
        },

        onFocus(): void {
            this.focused = true;
        },

        onInput(event: Event): void {
            if (!this.editable) {
                (event.target as HTMLInputElement).value = this.value;
                this.$emit('edit-request');
                return;
            }

            let value = (event.target as HTMLInputElement).value;

            if (this.trimNewLines) {
                value = value.replace(/\n/g, '');
            }

            // Fallback if the `maxlength` attribute does not work.
            if (this.maxlengthNumber && value.length > this.maxlengthNumber) {
                (event.target as HTMLInputElement).value = value.substring(0, this.maxlengthNumber);
                return;
            }

            this.value = value;

            const emit = (newValue: string) => {
                this.$emit('update:modelValue', newValue ?? '');
                this.debounce = null;
            };

            if (this.debounceMode && !this.debounce) {
                this.debounce = markRaw(debounce(emit, this.debounceInterval));
            }

            if (this.debounceMode && this.debounce) {
                this.debounce(value);
            } else {
                emit(value);
            }
        },
    },

    computed: {
        maxlengthNumber(): number {
            return parseInt(this.maxlength as string, 10) || 0;
        },

        displayValue(): string {
            if (this.value) {
                return this.value;
            }

            if (this.placeholder) {
                return this.value;
            }

            return this.t('untitled');
        },
    },

    watch: {
        modelValue(value: string) {
            // If the user is in the input window, ignore all changes.
            if (this.concurrencyMode && this.focused) {
                return;
            }

            this.value = value;
        },
    },

    created(): void {
        this.value = this.modelValue;
    },
});
