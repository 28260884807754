
// Svg
import ThemeSvg from '@/assets/theme.svg';
import SignOutSvg from '@/assets/sign-out.svg';
import SettingsSvg from '@/assets/settings.svg';

// Components
import VPopper from 'vue3-popper';

// Other
import oidc from '../vue-oidc-client';
import { defineComponent } from 'vue';
import { PropType } from 'vue';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';

type PlacementType =
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';

export default defineComponent({
    components: {
        ThemeSvg,
        SignOutSvg,
        SettingsSvg,

        VPopper,
    },

    props: {
        placement: { type: String as PropType<PlacementType>, default: 'bottom-end' },
        offsetDistance: { type: String, default: '-36' },
    },

    computed: {
        theme: {
            get(): 'light' | 'dark' {
                return Storages.Settings.getOrDefault(Settings.UI.Theme, 'light');
            },
            set(value: 'light' | 'dark'): void {
                Storages.Settings.set(Settings.UI.Theme, value);
                document.body.setAttribute('theme', value);
            },
        },
    },

    methods: {
        signOut(): void {
            oidc.signOut();
        },

        toggleTheme(): void {
            this.theme = this.theme === 'dark' ? 'light' : 'dark';
        },
    },
});
