
// Svg
import SearchSvg from '@/assets/search.svg';
import { debounce } from 'debounce';

// Components
//...

// Other
import { defineComponent } from 'vue';

type DebounceFunction = ((value: string) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {
        SearchSvg,
    },

    props: {
        debounceMode: { type: Boolean, default: false },
        debounceInterval: { type: Number, default: 500 },
        modelValue: { type: String, required: true, default: '' },
    },

    data: () => ({
        debounce: null as DebounceFunction | null,
    }),

    computed: {
        value: {
            get(): string {
                return this.modelValue;
            },

            set(value: string): void {
                if (!this.debounceMode) {
                    this.$emit('update:modelValue', value);
                    return;
                }

                if (!this.debounce) {
                    this.debounce = debounce((value: string) => {
                        this.debounce = null;
                        this.$emit('update:modelValue', value);
                    }, this.debounceInterval);
                }

                this.debounce(value);
            },
        },
    },
});
