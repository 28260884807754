import IApprovement from '@/core/Models/IApprovement';
import IStory from '@/core/Models/IStory';
import ITask from '@/core/Models/ITask';
import IUser from '@/core/Models/IUser';
import TaskService from '@/core/Services/TaskService';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import IAttachment from '@/core/Values/IAttachment';
import IChatComment from '@/core/Values/IChatComment';
import TaskType from '@/core/Values/TaskType';
import { $error } from '@/utils/app-utils';

export default {
    async changeTitleAsync(task: ITask, newTitle: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.setTitleAsync(task.id, newTitle);
        } catch (error) {
            $error(error);
        }
    },

    async changeDeadlineAsync(task: ITask, newDeadline: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.setDeadlineAsync(task.id, newDeadline);
        } catch (error) {
            $error(error);
        }
    },

    async changeDescriptionAsync(task: ITask, newDescription: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.setDescriptionAsync(task.id, newDescription);
        } catch (error) {
            $error(error);
        }
    },

    async changeAuthorAsync(task: ITask, newAuthorId: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.setAuthorAsync(task.id, newAuthorId);
        } catch (error) {
            $error(error);
        }
    },

    async createCommentAsync(task: ITask, comment: IChatComment) {
        if (!task) {
            return;
        }

        try {
            await TaskService.createCommentAsync(task.id, comment);
        } catch (error) {
            $error(error);
        }
    },

    async updateCommentAsync(task: ITask, commentId: number, newText: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.updateCommentAsync(task.id, commentId, newText);
        } catch (error) {
            $error(error);
        }
    },

    async createAttachmentAsync(task: ITask, attachment: IAttachment) {
        if (!task) {
            return;
        }

        try {
            await TaskService.createAttachmentAsync(task.id, attachment.objectName, attachment.fileName);
        } catch (error) {
            $error(error);
        }
    },

    async removeStoryAsync(task: ITask, story: IStory) {
        if (!task) {
            return;
        }

        try {
            await TaskService.deleteStoryAsync(task.id, story.id);
        } catch (error) {
            $error(error);
        }
    },

    async addCollaboratorAsync(task: ITask, { user, role }: { user: IUser; role: CollaboratorRole }) {
        if (!task) {
            return;
        }

        try {
            await TaskService.addCollaboratorAsync(task.id, user.id, role);
        } catch (error) {
            $error(error);
        }
    },

    async removeCollaboratorAsync(task: ITask, { user, role }: { user: IUser; role: CollaboratorRole }) {
        if (!task) {
            return;
        }

        try {
            await TaskService.removeCollaboratorAsync(task.id, user.id, role);
        } catch (error) {
            $error(error);
        }
    },

    async startApprovementProcessAsync(task: ITask, approverIds: string[]) {
        if (!task) {
            return;
        }

        try {
            await TaskService.startApprovementProcessAsync(task.id, approverIds);
        } catch (error) {
            $error(error);
        }
    },

    async completeAsync(task: ITask) {
        if (!task) {
            return;
        }

        try {
            await TaskService.completeAsync(task.id);
        } catch (error) {
            $error(error);
        }
    },

    async finishAsync(task: ITask) {
        if (!task) {
            return;
        }

        try {
            await TaskService.finishAsync(task.id);
        } catch (error) {
            $error(error);
        }
    },

    async toDraftAsync(task: ITask, event?: { callback?: () => void }) {
        if (!task) {
            return;
        }

        try {
            await TaskService.toDraftAsync(task.id);
            if (event?.callback) {
                event.callback();
            }
        } catch (error) {
            $error(error);
        }
    },

    async toInProgressAsync(task: ITask, comment?: string) {
        if (!task) {
            return;
        }

        try {
            await TaskService.toInProgressAsync(task.id, comment);
        } catch (error) {
            $error(error);
        }
    },

    async approveAsync(task: ITask, event: { approvement: IApprovement; comment?: string }) {
        if (!task) {
            return;
        }

        try {
            await TaskService.approveAsync(task.id, event.approvement.id, event.comment);
        } catch (error) {
            $error(error);
        }
    },

    async disapproveAsync(task: ITask, { approvement, comment }: { approvement: IApprovement; comment?: string }) {
        if (!task) {
            return;
        }

        try {
            await TaskService.disapproveAsync(task.id, approvement.id, comment);
        } catch (error) {
            $error(error);
        }
    },

    async archiveAsync(task: ITask) {
        if (!task) {
            return;
        }

        try {
            await TaskService.archiveAsync(task.id);
        } catch (error) {
            $error(error);
        }
    },

    async rollbackChangesAsync(task: ITask) {
        if (!task) {
            return;
        }

        try {
            await TaskService.rollbackChangesAsync(task.id);
        } catch (error) {
            $error(error);
        }
    },

    async createSubtaskAsync(task: ITask): Promise<void> {
        if (!task) {
            return;
        }

        try {
            await TaskService.createAsync(TaskType.Subtask, task.id);
        } catch (error) {
            $error(error);
        }
    },

    async changeParentTaskAsync(task: ITask, parentId: number): Promise<void> {
        if (!task) {
            return;
        }

        try {
            await TaskService.setParentAsync(task.id, parentId);
        } catch (error) {
            $error(error);
        }
    },
};
