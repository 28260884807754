
// Svg

// Components
import VSelect from './VSelect.vue';
import VUserCard from './VUserCard.vue';

// Other
import { debounce } from 'debounce';
import IUser from '@/core/Models/IUser';
import UserService from '@/core/Services/UserService';
import { defineComponent, markRaw } from 'vue';
import { PropType } from 'vue';

let initialOptions: IUser[] | null = null;

type DebounceFunction = ((search: string) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {
        VSelect,
        VUserCard,
    },

    props: {
        modelValue: { type: Array as PropType<IUser[]>, default: () => [] },
        multiple: { type: Boolean, default: false },
        editable: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
    },

    data: () => ({
        options: [] as IUser[],
        fetchOptionsDebounce: null as DebounceFunction | null,
    }),

    computed: {
        users: {
            get(): IUser[] {
                return this.modelValue;
            },

            set(value: IUser[]) {
                this.$emit('input', value ?? []);
                this.$emit('update:modelValue', value ?? []);
            },
        },
    },

    methods: {
        contains(user: IUser): boolean {
            return this.users.findIndex((item) => item.id === user.id) !== -1;
        },

        fetchOptionsIfNotExists() {
            if (!this.users.length) {
                this.fetchUsersAsync();
            }
        },

        fetchOptions(search: string, loading: (isLoading: boolean) => void) {
            if (!this.fetchOptionsDebounce) {
                this.fetchOptionsDebounce = debounce(async (search: string) => {
                    loading(true);
                    try {
                        await this.fetchUsersAsync(search);
                        this.fetchOptionsDebounce?.flush();
                        this.fetchOptionsDebounce = null;
                    } finally {
                        loading(false);
                    }
                }, 500); // 0.5s
            }

            this.fetchOptionsDebounce(search);
        },

        async fetchUsersAsync(search = '') {
            if (!search && initialOptions) {
                this.options = initialOptions;
                return;
            }

            const users = await UserService.query({
                page: 1,
                perPage: 10,
                search,
            });

            this.options = users.map((user) => markRaw(user));

            if (!initialOptions) {
                initialOptions = this.options;
            }
        },
    },

    mounted() {
        if (this.autofocus) {
            ((this.$el as HTMLDivElement | null)?.querySelector('.vs__search') as HTMLInputElement | null)?.focus();
        }
    },
});
