
// Svg
import TimesSvg from '@/assets/times.svg';

// Components
import VButton from './VButton.vue';

// Other
import { focusOnFirstTarget } from '@/utils/dom-utils';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        TimesSvg,

        VButton,
    },

    inheritAttrs: false,

    props: {
        visible: { type: Boolean, required: true },
    },

    methods: {
        close(): void {
            this.$emit('update:visible', false);
        },

        applyModalStyles() {
            if (this.visible && !document.body.classList.contains('v-modal-opened')) {
                (this.$refs.modal as HTMLDivElement | null)?.focus();
                document.body.classList.add('v-modal-opened');
            } else {
                document.body.classList.remove('v-modal-opened');
            }
        },

        focusOnModal() {
            setTimeout(() => {
                if (this.$refs.modal as HTMLDivElement | null) {
                    focusOnFirstTarget(this.$refs.modal as HTMLDivElement);
                }
            });
        },
    },

    watch: {
        visible() {
            this.applyModalStyles();
            if (this.visible) {
                this.focusOnModal();
            }
        },
    },

    mounted() {
        this.applyModalStyles();
    },
});
