import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import GoalPage from '../pages/GoalPage.vue';
import ProjectPage from '../pages/ProjectPage.vue';
import ProjectBoardPage from '../pages/ProjectBoardPage.vue';
import HomeTasksPage from '../pages/HomeTasksPage.vue';
import HomeGoalsPage from '../pages/HomeGoalsPage.vue';
import HomeSettingsPage from '../pages/HomeSettingsPage.vue';
import HomeApprovementsPage from '../pages/HomeApprovementsPage.vue';
import HomeNotificationsPage from '../pages/HomeNotificationsPage.vue';
import SharedTaskPanel from '../pages/SharedTaskPanel.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';
import oidc from '../vue-oidc-client';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        components: { default: HomeTasksPage, taskViewer: SharedTaskPanel },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/home/tasks',
        name: 'user.tasks',
        components: { default: HomeTasksPage, taskViewer: SharedTaskPanel },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/home/goals',
        name: 'user.goals',
        components: { default: HomeGoalsPage, taskViewer: SharedTaskPanel },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/home/notifications',
        name: 'user.notifications',
        components: { default: HomeNotificationsPage, taskViewer: SharedTaskPanel },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/home/approvements',
        name: 'user.approvements',
        components: { default: HomeApprovementsPage, taskViewer: SharedTaskPanel },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/home/settings',
        name: 'user.settings',
        components: { default: HomeSettingsPage },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/projects/:projectId',
        name: 'project.view',
        components: { default: ProjectPage, taskViewer: SharedTaskPanel },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/projects/:projectId/boards/:boardId',
        name: 'projects.board',
        components: { default: ProjectBoardPage, taskViewer: SharedTaskPanel },
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/goals/:goalId',
        name: 'goals.view',
        components: { default: GoalPage, taskViewer: SharedTaskPanel },
        meta: {
            authName: oidc.authName,
        },
    },
];

routes.push({
    path: '/:catchAll(.*)',
    name: 'not-found',
    components: { default: NotFoundPage },
    meta: {
        authName: oidc.authName,
    },
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

oidc.useRouter(router);

export default router;
