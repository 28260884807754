import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "image-viewer__row image-viewer__row--header" }
const _hoisted_2 = { class: "image-viewer__file-name" }
const _hoisted_3 = { class: "image-viewer__actions" }
const _hoisted_4 = ["href", "download"]
const _hoisted_5 = { class: "image-viewer__row image-viewer__row--center image-viewer__row--viewer" }
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadSvg = _resolveComponent("DownloadSvg")!
  const _component_TimesSvg = _resolveComponent("TimesSvg")!
  const _component_VButton = _resolveComponent("VButton")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.visible && _ctx.context)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "v-modal-overlay v-modal-overlay--fullscreen prevent-close",
          onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"]))
        }, [
          _createElementVNode("div", _mergeProps({
            ref: "modal",
            class: "v-modal v-modal--image-viewer image-viewer",
            tabindex: "-1"
          }, _ctx.$attrs), [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.context.fileName), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  target: "_blank",
                  class: "button button--transparent button--rounded image-viewer__button image-viewer__button--with-icon",
                  href: _ctx.context.downloadUri,
                  download: _ctx.context.fileName
                }, [
                  _createVNode(_component_DownloadSvg),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('download')), 1)
                ], 8, _hoisted_4),
                _createVNode(_component_VButton, {
                  class: "button--transparent button--rounded image-viewer__button image-viewer__button--close",
                  onClick: _ctx.close
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TimesSvg)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                class: "image-viewer__picture",
                src: _ctx.context.downloadUri,
                alt: _ctx.context.fileName
              }, null, 8, _hoisted_6)
            ])
          ], 16)
        ], 32))
      : _createCommentVNode("", true)
  ]))
}