class UI {
    public readonly Theme = 'application.ui.theme';
    public readonly Locale = 'application.ui.locale';
    public readonly Filters = 'application.ui.filters';
    public readonly StoryViewMode = 'application.ui.story-view-mode';
    public readonly NewComment = 'application.ui.new-comment';
    public readonly EditComment = 'application.ui.edit-comment';
    public readonly PanelWidth = 'application.ui.panel-width';
    public readonly SidebarWidth = 'application.ui.sidebar-width';
}

export default class Settings {
    public static readonly UI = new UI();
}
