
// Svg
// ...

// Components

// Other
import { defineComponent } from 'vue';

type DebounceFunction = (() => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    props: {
        alignCenter: { type: Boolean, default: false },
        justifyCenter: { type: Boolean, default: false },
        vertical: { type: Boolean, default: false },
    },
    data: () => ({
        scrollYNearEnd: false,
        debounce: null as DebounceFunction | null,
    }),
    methods: {
        onScroll(event: Event) {
            const scroll = event.target as HTMLDivElement;

            const prevScrollYNearEnd = this.scrollYNearEnd;
            this.scrollYNearEnd = scroll.scrollHeight - scroll.scrollTop - 400 < scroll.clientHeight;

            if (!prevScrollYNearEnd && this.scrollYNearEnd) {
                this.$emit('scroll-y-near-end');
            }
        },
    },
    computed: {
        horizontal(): boolean {
            return !this.vertical;
        },
    },
});
