
// Svg
import BottomArrowSvg from '@/assets/bottom-arrow.svg';

// Components
import BaseSelect from 'vue-select';

// Other
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        BottomArrowSvg,

        BaseSelect,
    },
});
